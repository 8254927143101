import { getH3Indexes } from "@/h3";
import { H3HexagonLayer } from "@deck.gl/geo-layers";

const HEXAGON_RES_LIST_DEFAULT = [0, 1, 2, 3];

const h3Settings = {
  opacity: 0.1,
  lineWidth: 1,
  lineColor: [255, 255, 255],
};

export const buildLayerName = (res) => `h3-hexagon-${res}`;

export const getHexagonLayer = (res, params) => {
  return new H3HexagonLayer({
    id: `h3-hexagon-${res}`,
    data: getH3Indexes(res),
    autoHighlight: true,
    wireframe: false,
    filled: false,
    stroked: true,
    pickable: false,
    extruded: false,
    lineWidthUnits: "pixels",
    opacity: h3Settings.opacity,
    getLineWidth: h3Settings.lineWidth,
    getLineColor: h3Settings.lineColor,
    ...params,
    getHexagon: (d) => d,
    getPolygonOffset: () => [0, -10000],
  });
};

export const getHexagonLayers = (resList = HEXAGON_RES_LIST_DEFAULT, params = {}) => {
  return {
    names: resList.map((res) => buildLayerName(res)),
    layers: resList.map((res) => getHexagonLayer(res, params)),
  };
};

export const hexagonLayerFilter = ({ layer, viewport }) => {
  if (layer.id === buildLayerName(0) && viewport.zoom > 3) {
    return false;
  }
  if (layer.id === buildLayerName(1) && viewport.zoom > 5) {
    return false;
  }
  if (layer.id === buildLayerName(2) && viewport.zoom < 3) {
    return false;
  }
  return !(layer.id === buildLayerName(3) && viewport.zoom < 5);
};
