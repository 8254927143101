<template>
  <div class="info-container not-clickable">
    <div class="info-header clickable">
      <div class="info-header__left">
        <v-btn class="go-back-btn clickable" @click="onGoBack" fixed icon large color="#fff">
          <v-icon size="36">mdi-chevron-left</v-icon>
        </v-btn>
      </div>
      <div class="info-header__center">
        <img class="logo" :src="require('@/assets/logo/logo-compose-exact.svg')" style="height: 60px" alt="Sync" />
      </div>
      <div class="info-header__right">{{ getDate }}</div>
    </div>

    <div class="info-footer clickable">
      <div class="info-footer__left">
        <router-link :to="{ name: 'Occasions' }">
          <img class="logo" :src="require('@/assets/logo/logo-choreograph-exact.svg')" alt="Choreograph" />
        </router-link>
      </div>
      <div class="info-footer__center"></div>
      <div class="info-footer__right">
        <div class="experiences clickable">
          <div>
            <router-link class="go-to-experience" :to="{ name: 'SelectExperience' }"> Launch Experiences </router-link>
          </div>
        </div>
      </div>
    </div>

    <div class="info-content clickable compose">
      <div class="compose__title">Occasions Graph supports end-to-end marketing process</div>
      <div class="compose__grid">
        <div class="compose-arrow arrow-analyze-insights">
          <svg
            ref="arrow-analyze-insights"
            width="27"
            height="303"
            viewBox="0 0 27 303"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M26.0711 4.53554L22.5355 8.07108" stroke="white" />
            <path d="M26.0711 4.53554L22.5355 1.00001" stroke="white" />
            <path
              d="M26 4.50003H19C9.05887 4.50003 1 12.5589 1 22.5V280.5C1 290.441 9.05888 298.5 19 298.5H26"
              stroke="white"
            />
          </svg>
        </div>
        <div class="compose-insights">
          <div class="compose-insights__content">
            <div class="data-container" ref="compose-insights">
              <div class="data-container-top">
                <div class="data-container__title">Insights</div>
                <div>
                  <IconTooltip
                    v-for="config in insightsTopIcons"
                    :key="config.src"
                    :icon-src="config.src"
                    :icon-text="config.text"
                  />
                </div>
              </div>

              <div class="data-container-bottom">
                <IconTooltip
                  v-for="config in insightsBottomIcons"
                  :key="config.src"
                  :icon-src="config.src"
                  :icon-text="config.text"
                />
              </div>

              <div>
                Align geospatial data model to connect campaign<br />
                performance to business outcomes
              </div>
            </div>

            <div class="compose-arrow arrow-insights-3">
              <svg
                ref="arrow-insights-3"
                width="27"
                height="207"
                viewBox="0 0 27 207"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M25 5H21.0734C16.6551 5 13.0734 8.58172 13.0734 13V44C13.0734 48.4183 9.49167 52 5.07339 52H0"
                  stroke="white"
                />

                <path d="M25.0711 4.86446L21.5355 8.39999" stroke="white" />
                <path d="M25.0711 4.86446L21.5355 1.32893" stroke="white" />

                <path d="M25.0711 98.9645L21.5355 102.5" stroke="white" />
                <path d="M25.0711 98.9645L21.5355 95.4289" stroke="white" />

                <path d="M25.0711 195.964L21.5355 199.5" stroke="white" />
                <path d="M25.0711 195.964L21.5355 192.429" stroke="white" />

                <path
                  d="M25 196H20.0557C15.6158 196 12.0251 192.384 12.0559 187.944L12.9441 60.0556C12.9749 55.6157 9.38424 52 4.94425 52H0"
                  stroke="white"
                />
                <path d="M24.3 99H20.8C16.3817 99 12.8 95.4183 12.8 91V90" stroke="white" />
              </svg>
            </div>
          </div>
        </div>

        <div class="compose-brief line-wrapper">
          <div class="data-container short-line" ref="compose-brief">
            <div class="data-container__title">Brief</div>

            <div class="text-container">Inform holistic strategy<br />(for creative and media)</div>

            <div class="icons-area">
              <IconTooltip icon-src="path-crosses.svg" icon-text="Path" />
            </div>

            <div class="icons-area">
              <IconTooltip icon-src="adobe-workout.png" icon-text="Adobe Workout" />
            </div>
          </div>

          <div class="compose-arrow arrow-brief-plan">
            <svg
              ref="arrow-brief-plan"
              width="12"
              height="25"
              viewBox="0 0 12 27"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M4 24.7313L4 0.6" stroke="white" />
              <path d="M3.61538 25.6L7 22" stroke="white" />
              <path d="M4.15383 25.6L0.999998 22" stroke="white" />
            </svg>
          </div>
        </div>

        <div class="compose-plan line-wrapper">
          <div class="data-container short-line" ref="compose-plan">
            <div class="data-container__title">Plan</div>

            <div class="text-container">Forecast across paid and<br />owned channels</div>

            <div class="icons-area">
              <IconTooltip
                v-for="config in planIcons"
                :key="config.src"
                :icon-src="config.src"
                :icon-text="config.text"
              />
            </div>

            <div class="icons-area">
              <IconTooltip icon-src="c9h-architect.png" icon-text="Choreograph Architect" />
            </div>
          </div>

          <div class="compose-arrow arrow-plan-create">
            <svg
              ref="arrow-plan-create"
              width="12"
              height="25"
              viewBox="0 0 12 27"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M4 24.7313L4 0.6" stroke="white" />
              <path d="M3.61538 25.6L7 22" stroke="white" />
              <path d="M4.15383 25.6L0.999998 22" stroke="white" />
            </svg>
          </div>
        </div>

        <div class="compose-create line-wrapper">
          <div class="data-container short-line" ref="compose-create">
            <div class="data-container__title">Create</div>

            <div class="text-container">Generate relevant content<br />in real time</div>

            <div class="icons-area">
              <IconTooltip
                v-for="config in createIcons"
                :key="config.src"
                :icon-src="config.src"
                :icon-text="config.text"
              />
            </div>

            <div class="icons-area">
              <IconTooltip icon-src="c9h-create.png" icon-text="Choreoghraph Create" />
            </div>
          </div>

          <div class="compose-arrow arrow-create-distribute">
            <svg
              ref="arrow-create-distribute"
              width="12"
              height="25"
              viewBox="0 0 12 27"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M4 24.7313L4 0.6" stroke="white" />
              <path d="M3.61538 25.6L7 22" stroke="white" />
              <path d="M4.15383 25.6L0.999998 22" stroke="white" />
            </svg>
          </div>
        </div>

        <div class="compose-distribute line-wrapper">
          <div class="data-container long-line" ref="compose-distribute">
            <div class="data-container__title">Distribute</div>

            <div class="text-container">Send non-PII cohorts to activation platforms</div>

            <div class="icons-area">
              <IconTooltip icon-src="equalizer.svg" icon-text="Equalizer" />
            </div>

            <div class="icons-area">
              <IconTooltip
                v-for="config in distributeIcons"
                :key="config.src"
                :icon-src="config.src"
                :icon-text="config.text"
              />
            </div>
          </div>

          <div class="compose-arrow arrow-distribute-analyze">
            <svg
              ref="arrow-distribute-analyze"
              width="12"
              height="25"
              viewBox="0 0 12 27"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M4 24.7313L4 0.6" stroke="white" />
              <path d="M3.61538 25.6L7 22" stroke="white" />
              <path d="M4.15383 25.6L0.999998 22" stroke="white" />
            </svg>
          </div>
        </div>

        <div class="compose-analyze line-wrapper">
          <div class="data-container long-line" ref="compose-analyze">
            <div class="data-container__title">Analyze & Optimize</div>

            <div class="text-container">Leverage AI (to enhance relevancy and performance)</div>

            <div class="icons-area">
              <IconTooltip
                v-for="config in analyzeLeftIcons"
                :key="config.src"
                :icon-src="config.src"
                :icon-text="config.text"
              />
            </div>

            <div class="icons-area">
              <IconTooltip
                v-for="config in analyzeRightIcons"
                :key="config.src"
                :icon-src="config.src"
                :icon-text="config.text"
              />
            </div>
          </div>
        </div>

        <div class="arrows-right">
          <svg
            ref="arrow-brief-create"
            class="compose-arrow arrow-brief-create"
            width="13"
            height="199"
            viewBox="0 0 13 199"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M0.72188 195.932L5 193.363" stroke="white" />
            <path d="M0.72188 195.932L5 198.5" stroke="white" />
            <path
              d="M1.48923 195.957H5.67157C8.98528 195.957 11.6716 193.271 11.6716 189.957V6.5C11.6716 3.18629 8.98527 0.5 5.67157 0.5H1.48923"
              stroke="white"
            />
          </svg>

          <svg
            ref="arrow-plan-distribute"
            class="compose-arrow arrow-plan-distribute"
            width="26"
            height="199"
            viewBox="0 0 26 199"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M0.72188 195.932L5 193.363" stroke="white" />
            <path d="M0.72188 195.932L5 198.5" stroke="white" />
            <path
              d="M0.489258 195.957H7.48926C17.4304 195.957 25.4893 187.899 25.4893 177.957V18.5C25.4893 8.55887 17.4304 0.5 7.48926 0.5H0.489258"
              stroke="white"
            />
          </svg>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapMutations } from "vuex";
import { timeout } from "@/utils/misc";
import { getDateString } from "@/utils/dateTime";
import { Animation } from "@/components/utils/animation";
import IconTooltip from "@/components/IconTooltip";

import "swiper/css/swiper.css";

const flashingBoxConfig = { nextStepIn: 1000, stopIn: 4000, className: "flashing-box" };
const flashingArrowConfig = { nextStepIn: 3000, stopIn: 4000, className: "flashing-arrow" };

const config = [
  { refs: ["compose-insights"], ...flashingBoxConfig },
  { refs: ["arrow-insights-3"], ...flashingArrowConfig },
  { refs: ["compose-brief", "compose-plan", "compose-create"], ...flashingBoxConfig },
  { refs: ["arrow-brief-plan", "arrow-brief-create"], ...flashingArrowConfig },
  { refs: ["compose-plan"], ...flashingBoxConfig },
  { refs: ["arrow-plan-create", "arrow-plan-distribute"], ...flashingArrowConfig },
  { refs: ["compose-create"], ...flashingBoxConfig },
  { refs: ["arrow-create-distribute"], ...flashingArrowConfig },
  { refs: ["compose-distribute"], ...flashingBoxConfig },
  { refs: ["arrow-distribute-analyze"], ...flashingArrowConfig },
  { refs: ["compose-analyze"], ...flashingBoxConfig },
  { refs: ["arrow-analyze-insights"], ...flashingArrowConfig },
];

export default {
  name: "Compose",
  components: { IconTooltip },
  data() {
    return {
      isShowAnimation: true,
      animation: new Animation({
        context: this,
        config,
        initStep: 0,
        infinite: true,
      }),
      insightsTopIcons: [
        { src: "magnifier.svg", text: "Magnifier" },
        { src: "atom.svg", text: "Atom" },
      ],
      insightsBottomIcons: [
        { src: "azure.png", text: "azure" },
        { src: "snowflake.png", text: "Snowflake Data Cloud" },
        { src: "aws.png", text: "Amazon Web Services" },
        { src: "hexagon-bry.png", text: "Google Cloud" },
      ],
      planIcons: [
        { src: "usd.svg", text: "USD" },
        { src: "equalizer.svg", text: "Equalizer" },
      ],
      createIcons: [
        { src: "bulb.svg", text: "Bulb" },
        { src: "gear.svg", text: "Gear" },
      ],
      distributeIcons: [
        { src: "google.png", text: "Google" },
        { src: "meta.png", text: "Meta" },
        { src: "open-x.png", text: "OpenX" },
      ],
      analyzeLeftIcons: [
        { src: "magnifier.svg", text: "Magnifier" },
        { src: "usd.svg", text: "USD" },
        { src: "equalizer.svg", text: "Equalizer" },
      ],
      analyzeRightIcons: [
        { src: "gain-theory.png", text: "Gain Theory" },
        { src: "finecast.png", text: "Finecast" },
        { src: "groupm-nexus.png", text: "GroupM Nexus" },
      ],
    };
  },

  beforeDestroy() {
    this.changeIsInitialLoading(true);
    this.setComposeLoading(true);
  },

  created() {
    this.setGlobeMap(false);
  },

  async mounted() {
    await timeout(3000);
    this.setComposeLoading(false);
    this.animation.startAnimation();
  },

  computed: {
    getDate() {
      return getDateString();
    },
  },

  methods: {
    ...mapMutations("app", ["setGlobeMap", "changeIsInitialLoading"]),
    ...mapMutations("compose", ["setComposeLoading"]),

    onGoBack() {
      this.$router.go(-1);
    },
  },

  watch: {
    isShowAnimation(val) {
      if (val) {
        this.animation.startAnimation();
      } else {
        this.animation.stopAnimation();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
$flashing-background-initial-color: rgba(33, 33, 33, 0.5);
$flashing-border-initial-color: rgba(255, 255, 255, 0.5);

.compose {
  font-size: 16px;
  line-height: 1.2;

  &.info-content {
    flex-direction: column;
    padding-left: 28px;
    padding-right: 28px;
  }

  &__title {
    text-align: center;
    font-size: 36px;
    padding-bottom: 1em;
  }

  &__grid {
    display: grid;
    grid-template-areas:
      "cell-left cell-insights cell-brief cell-right"
      "cell-left cell-insights cell-plan cell-right"
      "cell-left cell-insights cell-create cell-right"
      "cell-left cell-distribute cell-distribute cell-right"
      "cell-left cell-analyze cell-analyze cell-right";
    grid-template-columns: 25px 1fr 1.4fr 25px;
    grid-template-rows: 1fr 1fr 1fr 1fr 1fr;

    .arrow-analyze-insights {
      grid-area: cell-left;
      display: flex;
      align-items: flex-start;
      padding-top: 120px;
    }

    .arrow-insights-3 {
      display: flex;
      align-items: center;
    }

    .arrow-brief-plan,
    .arrow-plan-create,
    .arrow-create-distribute,
    .arrow-distribute-analyze {
      display: flex;
      justify-content: center;
    }

    .arrows-right {
      grid-area: cell-right;
      position: relative;
      display: flex;
      align-items: flex-start;

      .arrow-brief-create {
        position: absolute;
        left: 0;
        top: 35px;
      }

      .arrow-plan-distribute {
        position: absolute;
        left: 0;
        top: 125px;
      }
    }

    .compose-brief {
      grid-area: cell-brief;
    }

    .compose-plan {
      grid-area: cell-plan;
    }

    .compose-create {
      grid-area: cell-create;
    }

    .compose-distribute {
      grid-area: cell-distribute;
    }

    .compose-analyze {
      grid-area: cell-analyze;
    }

    .compose-insights {
      grid-area: cell-insights;
      display: grid;
      grid-template-rows: 1fr 25px;

      &__content {
        display: grid;
        grid-template-columns: 1fr 25px;

        .data-container {
          padding-left: 16px;
          padding-right: 22px;
          padding-top: 44px;

          .data-container-top {
            display: flex;
            justify-content: space-between;
          }

          .data-container-bottom {
            display: flex;
            justify-content: flex-end;
          }
        }
      }
    }

    .data-container {
      display: grid;
      grid-gap: 12px;
      align-items: center;
      background: $flashing-background-initial-color;
      border: 1px solid $flashing-border-initial-color;
      backdrop-filter: blur(40px);
      padding: 10px 16px;
      z-index: 10;

      &__title {
        font-size: 24px;
        font-weight: 400;
        text-transform: uppercase;
        letter-spacing: 0.08em;
      }
    }

    .line-wrapper {
      display: grid;
      grid-template-rows: 1fr 25px;
    }

    .short-line {
      grid-template-columns: 1.2fr 2fr 1fr 2fr;

      .icons-area:last-child {
        justify-content: center;
      }
    }

    .long-line {
      grid-template-columns: 1.1fr 1.4fr 0.8fr 1fr;

      .icons-area:last-child {
        justify-content: space-between;
        padding-left: 12px;
        padding-right: 12px;
      }
    }

    .icons-area {
      display: flex;
      align-items: center;
      justify-content: flex-end;
    }

    .vendor-icon {
      height: 48px;
      margin-left: 16px;
    }

    .compose-arrow {
      path {
        stroke-width: 2;
        stroke-linecap: round;
        stroke: rgba(180, 180, 180, 1);
      }
    }
  }

  .flashing-box {
    animation-name: flashing-box;
    animation-duration: 2000ms;
    animation-timing-function: ease;
    animation-iteration-count: 2;
    animation-direction: alternate;
    animation-fill-mode: backwards;
  }
  @keyframes flashing-box {
    from {
      border-color: $flashing-border-initial-color;
      background-color: $flashing-background-initial-color;
      box-shadow: 0 0 0 rgba(255, 255, 255, 1);
      width: 100%;
      height: 100%;
    }
    to {
      border-color: rgba(255, 255, 255, 1);
      background-color: rgba(0, 0, 0, 0.66);
      box-shadow: 0 0 6px 1px rgba(255, 255, 255, 1);
      transform: scale(1.008, 1.05);
    }
  }

  .flashing-arrow {
    path {
      animation-name: flashing-arrow;
      animation-duration: 500ms;
      animation-timing-function: linear;
      animation-iteration-count: infinite;
      animation-direction: normal;

      stroke-dasharray: 4;
      stroke-dashoffset: 0;
      stroke: rgba(255, 255, 255, 1);
    }
  }
  @keyframes flashing-arrow {
    from {
      stroke-dashoffset: 0;
    }
    to {
      stroke-dashoffset: 16;
    }
  }
}
</style>
