<template>
  <div class="map-component-container">
    <div style="background: black" id="map"></div>
  </div>
</template>

<script>
import "mapbox-gl/dist/mapbox-gl.css";

import layerManager from "@/deck/layerManager";

export default {
  name: "BaseFlatMap",
  props: {
    viewState: Object,
    mapStyle: String,
    setViewState: Function,
    resetViewState: Function,
    setMapLoaded: Function,
    zoomOutAnimation: Boolean,
    animationStatus: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      idAnimation: null,
    };
  },
  watch: {
    zoomOutAnimation() {
      if (this.zoomOutAnimation && this.animationStatus) {
        this.startAnimation();
      } else {
        this.stopAnimation();
      }
    },
  },
  mounted() {
    this.setupDeck();
  },
  beforeDestroy() {
    this.resetViewState();
    layerManager.destroy();
    this.stopAnimation();
  },

  methods: {
    startAnimation() {
      this.idAnimation = setInterval(() => {
        this.setViewState({
          ...this.viewState,
          bearing: this.viewState.bearing + 0.2,
          zoom: this.viewState.zoom <= 12 ? this.viewState.zoom + 0.005 : this.viewState.zoom,
          transitionInterpolator: this.transitionInterpolator,
        });
      }, 20);
    },

    stopAnimation() {
      clearInterval(this.idAnimation);
    },

    setupDeck() {
      if (layerManager.deckInstance) {
        return;
      }
      layerManager.init({
        container: "#map",
        mapStyle: this.mapStyle,
        initialViewState: this.viewState,
        controller: { doubleClickZoom: false },
        onViewStateChange: ({ viewState }) => {
          this.stopAnimation();
          this.setViewState(viewState);
        },
        onLoad: () => {
          this.setMapLoaded();
        },
      });
    },
  },
};
</script>

<style>
.mapboxgl-ctrl.mapboxgl-ctrl-attrib {
  display: none !important;
}
</style>

<style scoped lang="scss">
.map-component-container {
  position: relative;
  width: 100%;
  height: 100%;

  #map {
    height: 100%;
    top: 0;
    left: 0;
  }

  .map-controls {
    position: absolute;
    bottom: 20px;
    left: 20px;
    border-radius: 4px;
    box-shadow: 0 1px 4px 0 rgba(26, 30, 41, 0.2);
    background-color: #fff;

    button {
      padding: 2px;

      &:first-child {
        padding-right: 16px;
      }

      &:last-child {
        padding-left: 16px;
      }

      &:hover {
        background-color: darken(#fff, 5%);
      }
    }
  }
}
</style>
