import cities from "./citiesOccasions.json";
import citiesSpotify from "./citiesOccasionsSpotify.json";

export const getPredefinedOccasionNames = async () => {
  return [
    "workout",
    "breakfast",
    "on the move",
    "eating out",
    "studying",
    "working",
    "live event",
    "pause at work",
    "social media",
    "going out",
    "watching a movie",
    "playing video games online",
    "diner",
    "lunch",
    "socialising",
    "binge watching series",
    "gaming",
    "snack time",
    "pause at",
    "watching an e-sport game",
    "me time",
    "snack at university",
    "commute to university",
    "commute to home",
  ];
};

export const getPredefinedOccasionCategories = async (isSpotify) => {
  const listCities = isSpotify ? citiesSpotify : cities;

  const contexts = new Set();
  const needStates = new Set();
  const choiceDrivers = new Set();

  listCities.forEach((city) => {
    city.occasions.forEach((occasion) => {
      contexts.add(occasion.context.toLowerCase());
      needStates.add(occasion.need_state.toLowerCase());
      choiceDrivers.add(occasion.choice_driver.toLowerCase());
    });
  });
  return {
    contexts: Array.from(contexts),
    needStates: Array.from(needStates),
    choiceDrivers: Array.from(choiceDrivers),
  };
};

// TODO: Check do we need to hold this for some reasons.
// export const getPredefinedOccasionCategories = async () => {
//   return {
//     contexts: ["physical activity", "routine", "socializing", "in transit", "focus", "live event"],
//     needStates: ["thirsty", "hungry", "tired"],
//     choiceDrivers: ["health conscious", "social responsibility", "trend follower", "brand loyalist", "indulgence"],
//   };
// };
