import Vue from "vue";
import Vuex from "vuex";

import app from "@/store/modules/app/app";
import user from "@/store/modules/user/user";
import baseMap from "@/store/modules/maps/baseMap";
import mainGlobe from "@/store/modules/maps/mainGlobe";
import occasions from "@/store/modules/occasions/occasions";
import compose from "@/store/modules/compose/compose";
import selectOccasion from "@/store/modules/selectOccasion/selectOccasion";
import selectExperience from "@/store/modules/selectExperience/selectExperience";

Vue.use(Vuex);

export default new Vuex.Store({
  namespaced: true,
  modules: {
    app,
    user,
    baseMap,
    mainGlobe,
    occasions,
    compose,
    selectOccasion,
    selectExperience,
  },
  state: {},
  mutations: {},
  actions: {},
  getters: {},
});
