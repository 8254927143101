import { BitmapLayer } from "@deck.gl/layers";
import { TileLayer } from "@deck.gl/geo-layers";

import { earthTileMapDefault } from "@/deck/maps";

export const createEarthTileLayer = (params) =>
  new TileLayer({
    data: earthTileMapDefault,
    minZoom: 0,
    maxZoom: 19,
    tileSize: 256,
    ...params,

    renderSubLayers: (props) => {
      const {
        bbox: { west, south, east, north },
      } = props.tile;

      return new BitmapLayer(props, {
        data: null,
        image: props.data,
        bounds: [west, south, east, north],
      });
    },
  });
