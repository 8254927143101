export const formatTime = (updateOptions) => {
  return (data) => {
    let options = {
      hour: "numeric",
      minute: "numeric",
    };
    options = { ...options, ...updateOptions };
    const dateUpdate = new Date(data);
    return dateUpdate.toLocaleTimeString(navigator.language, options);
  };
};

export const formatDate = (data, updateOptions = {}) => {
  let options = {
    month: "numeric",
    year: "numeric",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
    timeZone: "America/New_York",
  };
  options = { ...options, ...updateOptions };
  const dateUpdate = new Date(data);
  return dateUpdate.toLocaleDateString("en-US", options);
};

export const getDateString = () => {
  const today = new Date();
  const month = today.toLocaleString("en-US", { month: "long" });
  const day = today.getDate();
  const year = today.getFullYear();
  return day + " " + month + " " + year;
};
