import { Deck } from "@deck.gl/core";
import { BASEMAP } from "@deck.gl/carto";

import mapboxgl from "./mapboxgl";
import store from "../store";

const CANVAS_STYLE = {
  position: "absolute",
  left: 0,
  top: 0,
  width: "100%",
  height: "100%",
};

const TOOLTIP_STYLE = {
  color: "#fff",
  opacity: "0.9",
  borderRadius: "0.25rem",
  textTransform: "capitalize",
  fontFamily: "Urbanist, sans-serif",
  fontSize: "0.7rem",
};

const DEFAULT_MAP_PROPS = {
  layers: [],
  basemap: null,
  controller: true,
  useDevicePixels: 2,
  getCursor: ({ isDragging, isHovering }) => (isDragging ? "grabbing" : isHovering ? "pointer" : ""),
  getTooltip,
  layerFilter({ layer, viewport }) {
    const filterFn = layer.props.layerFilter;
    if (typeof filterFn === "function") {
      return filterFn(viewport, layer);
    }
    return true;
  },
};

function getTooltip(pickingInfo) {
  if (!pickingInfo.coordinate) {
    return null;
  }

  const [x, y] = pickingInfo.coordinate;
  const html = `
    <div style="font-size: 0.9rem;"><strong>Coordinates</strong></div>
    <div>Longitude / latitude: ${x.toFixed(5)}, ${y.toFixed(5)}.</div>
  </div>`;

  return { html, style: TOOLTIP_STYLE };
}

function createCanvas(props) {
  let { container = document.body } = props;

  if (typeof container === "string") {
    container = document.querySelector(container);
  }

  if (!container) {
    throw new Error("[DeckMap] container not found");
  }

  const containerStyle = window.getComputedStyle(container);
  if (containerStyle.position === "static") {
    container.style.position = "relative";
  }

  const mapboxCanvas = document.createElement("div");
  container.appendChild(mapboxCanvas);
  Object.assign(mapboxCanvas.style, CANVAS_STYLE);

  const deckCanvas = document.createElement("canvas");
  deckCanvas.oncontextmenu = () => false; // this disables right click on map
  container.appendChild(deckCanvas);
  Object.assign(deckCanvas.style, CANVAS_STYLE);

  return { container, mapboxCanvas, deckCanvas };
}

/**
 * @params container (Element) - DOM element to add deck.gl canvas to
 * @params map (Object) - map API. Set to falsy to disable
 */
export default class DeckMap extends Deck {
  constructor(props = {}) {
    props = {
      ...DEFAULT_MAP_PROPS,
      onResize: () => {
        if (this._map) {
          this._map.resize();
        }
      },
      ...props,
    };
    const { baseMap, mapStyle, viewState, initialViewState } = props;
    const currentViewState = viewState || initialViewState;
    const { mapboxCanvas, deckCanvas } = createCanvas(props);

    super({ canvas: deckCanvas, ...props });

    if (baseMap) {
      this._map = baseMap;
    } else {
      this._map = new mapboxgl.Map({
        container: mapboxCanvas,
        style: mapStyle || BASEMAP.VOYAGER_NOLABELS,
        interactive: false,
        center: [currentViewState.longitude, currentViewState.latitude],
        zoom: currentViewState.zoom,
        bearing: currentViewState.bearing || 0,
        pitch: currentViewState.pitch || 0,
        flyTo: true,
      });
    }

    this._onBeforeRender = (params) => {
      this.onBeforeRender(params);
      if (this._map) {
        const viewport = this.getViewports()[0];
        this._map.jumpTo({
          center: [viewport.longitude, viewport.latitude],
          zoom: viewport.zoom,
          bearing: viewport.bearing,
          pitch: viewport.pitch,
        });
        this.redrawMapbox();
        store.commit("app/handleMapLoadingState");
      }
    };
  }

  redrawMapbox() {
    const map = this._map;

    if (map.style) {
      if (map._frame) {
        map._frame.cancel();
        map._frame = null;
      }

      map._render();
    }
  }

  getMapboxMap() {
    return this._map;
  }

  finalize() {
    if (this._map) {
      this._map.remove();
    }
    super.finalize();
  }

  setProps(props) {
    if ("onBeforeRender" in props && this._onBeforeRender && props.onBeforeRender !== this._onBeforeRender) {
      this.onBeforeRender = props.onBeforeRender;
      props.onBeforeRender = this._onBeforeRender;
    }

    super.setProps(props);
  }
}
