export const RAPID_API_KEY = process.env.VUE_APP_RAPID_API_KEY;
export const MAPBOX_KEY = process.env.VUE_APP_MAPBOX_KEY;
export const MAPBOX_KEY_2 = process.env.VUE_APP_MAPBOX_KEY_2;

export const earthTileMaps = {
  // DEFAULT: steppechange: HYP_HR_SR_OB_DR
  hypHrSr: `https://api.mapbox.com/styles/v1/steppechange/cl3stylrm000q14npmz2m8om9/tiles/{z}/{x}/{y}?access_token=${MAPBOX_KEY}`,
  // DESIGN R01: steppechange: HYP_HR_SR_OB_DR_r01
  hypHrSrR01: `https://api.mapbox.com/styles/v1/steppechange/cl44b2bmz000t14mo4os6e49y/tiles/{z}/{x}/{y}?access_token=${MAPBOX_KEY_2}`,
  // DESIGN R02: steppechange: HYP_HR_SR_OB_DR_r02
  hypHrSrR02: `https://api.mapbox.com/styles/v1/steppechange/cl461lhya007o14pelmbpey4o/tiles/{z}/{x}/{y}?access_token=${MAPBOX_KEY_2}`,
  // DESIGN R01 + Night: steppechange: HYP_HR_SR_OB_DR_r01-night
  hypHrSrR01Night: `https://api.mapbox.com/styles/v1/steppechange/cl45e7r39002i15nky592r9oa/tiles/{z}/{x}/{y}?access_token=${MAPBOX_KEY_2}`,
  // steppechange: HYP_HR_SR_OB_DR-BlackMarble
  hypHrSrBlackMarble: `https://api.mapbox.com/styles/v1/steppechange/cl3yauam8002915o5i1a3hrcg/tiles/{z}/{x}/{y}?access_token=${MAPBOX_KEY}`,
  // FIRST OUR GOOD RESULT: steppechange: HYP_HR_SR_OB_DR-BlackMarble
  hypHrSrBlackMarble2: `https://api.mapbox.com/styles/v1/steppechange/cl3yv830p002e14plo1wt5few/tiles/{z}/{x}/{y}?access_token=${MAPBOX_KEY_2}`,

  // MapTiles trough RapidAPI
  mapTiles: `https://maptiles.p.rapidapi.com/en/map/v1/{z}/{x}/{y}.png?rapidapi-key=${RAPID_API_KEY}`,
  // MapBox satellite (dark oceans
  satellite9: `https://api.mapbox.com/styles/v1/mapbox/satellite-v9/tiles/{z}/{x}/{y}?access_token=${MAPBOX_KEY}`,
  // steppechange: Streets Simple
  streetsSimple: `https://api.mapbox.com/styles/v1/steppechange/cl3ohqkaw001515o5ycn6rgsb/tiles/{z}/{x}/{y}?access_token=${MAPBOX_KEY}`,
  // steppechange: Satellite Streets
  satelliteStreets: `https://api.mapbox.com/styles/v1/steppechange/cl3nbxoez006o14mli0id8zdr/tiles/{z}/{x}/{y}?access_token=${MAPBOX_KEY}`,
  // steppechange: NE1_50M_SR_W
  ne50M: `https://api.mapbox.com/styles/v1/steppechange/cl3poonxp005r15ms7jfwqex1/tiles/{z}/{x}/{y}?access_token=${MAPBOX_KEY}`,
  // steppechange: NE1_50M_SR_W_exp (with work EXPERIMENT)
  ne50MExp: `https://api.mapbox.com/styles/v1/steppechange/cl3r6rslt006514plhfp3gpc1/tiles/{z}/{x}/{y}?access_token=${MAPBOX_KEY_2}`,
  // steppechange: BlackMarble_2016_01deg (Earth at the night)
  blackMarble2016: `https://api.mapbox.com/styles/v1/steppechange/cl3skojfa000014pla1zwcpmy/tiles/{z}/{x}/{y}?access_token=${MAPBOX_KEY}`,
  // steppechange: Monochrome Blue 3D Buildings
  monoBlue3d: `https://api.mapbox.com/styles/v1/steppechange/cl3ztnnwz000c15l47eh5i1wm/tiles/{z}/{x}/{y}?access_token=${MAPBOX_KEY_2}`,
};

export const earthTileMapDefault = earthTileMaps.hypHrSr;

export const mapStyles = {
  // steppechange: MonoBlue 3D
  monoBlue3d: `mapbox://styles/steppechange/cl3ztnnwz000c15l47eh5i1wm?access_token=${MAPBOX_KEY_2}`,
  // steppechange: MonoBlack 3D
  monoBlack3d: `mapbox://styles/steppechange/cl4abyx52000w14odd3vp8i1j?access_token=${MAPBOX_KEY_2}`,
};
