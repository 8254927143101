export default {
  namespaced: true,

  state: {
    occasions: [],
    selectedOccasion: null,
  },

  mutations: {
    updateOccasion(state, occasions) {
      state.occasions = occasions;
    },

    updateSelected(state, selectedOccasion) {
      state.selectedOccasion = selectedOccasion;
    },
  },

  getters: {
    occasions(state) {
      return state.occasions;
    },

    selectedOccasion(state) {
      return state.selectedOccasion;
    },
  },
};
