export const data = {
  music: [
    {
      cityId: "atlanta",
      lat: 33.7678358,
      long: -84.4906438,
      occasions: [
        {
          occasion_name: "live event",
          occasion_title: "Gig Night Out",
          occasion_index: 94,
          context: "leisure",
          need_state: "connect",
          choice_driver: "music lover",
          city: "atlanta",
          qr: [
            {
              title: "The Kid LAROI",
              type: "image",
              file: "qrcode.65269564.png",
            },
            {
              title: "Khalid",
              type: "image",
              file: "qrcode.65269578.png",
            },
            {
              title: "Ari Lennox",
              type: "image",
              file: "qrcode.65269581.png",
            },
            {
              title: "Behind the Scenes",
              type: "video",
              file: "BehindTheScenes.mp4",
            },
          ],
        },
      ],
    },
    {
      cityId: "mumbai",
      lat: 19.0825223,
      long: 72.7411014,
      occasions: [
        {
          occasion_name: "diner",
          occasion_title: "Easy Solo Dinner",
          occasion_index: 93,
          context: "eating",
          need_state: "health",
          choice_driver: "music lover",
          city: "mumbai",
          qr: [
            {
              title: "Arjun Kanungo",
              type: "image",
              file: "qrcode.65269591.png",
            },
            {
              title: "Tesher",
              type: "image",
              file: "qrcode.65269589.png",
            },
            {
              title: "Amit Trivedi",
              type: "image",
              file: "qrcode.65269590.png",
            },
            {
              title: "Behind the Scenes",
              type: "video",
              file: "BehindTheScenes.mp4",
            },
          ],
        },
      ],
    },
    {
      cityId: "madrid",
      lat: 40.2459,
      long: -3.4209,
      occasions: [
        {
          occasion_name: "social media",
          occasion_title: "Social Scrolling",
          occasion_index: 92,
          context: "leisure",
          need_state: "relax",
          choice_driver: "foodie",
          city: "madrid",
          qr: [
            {
              title: "Griff",
              type: "image",
              file: "qrcode.65269584.png",
            },
            {
              title: "Mariah Angeliq",
              type: "image",
              file: "qrcode.65269586.png",
            },
            {
              title: "Behind the Scenes",
              type: "video",
              file: "BehindTheScenes.mp4",
            },
          ],
        },
      ],
    },
  ],
  sports: [
    {
      cityId: "buenos aires",
      lat: -37.0564038,
      long: -64.5144893,
      occasions: [
        {
          occasion_name: "going out",
          occasion_title: "Team Sports",
          occasion_index: 91,
          context: "physical activity",
          need_state: "connect",
          choice_driver: "sports fan",
          city: "buenos aires",
          qr: [
            {
              type: "video",
              file: "CokeCan_65276420_QR1_Sports_Argentina.mp4",
            },
          ],
        },
      ],
    },
    {
      cityId: "johannesburg",
      lat: -26.1713505,
      long: 27.9699847,
      occasions: [
        {
          occasion_name: "socialising",
          occasion_title: "Brunch with Friends",
          occasion_index: 92,
          context: "socializing",
          need_state: "connect",
          choice_driver: "sports fan",
          city: "johannesburg",
          qr: [
            {
              type: "video",
              file: "CokeCan_65276408_QR2_Sports_SA.mp4",
            },
          ],
        },
      ],
    },
  ],
  gaming: [
    {
      cityId: "london",
      lat: 51.5287718,
      long: -0.2416806,
      occasions: [
        {
          occasion_name: "pause at work",
          occasion_title: "Healthy Afternoon Snack",
          occasion_index: 93,
          context: "working",
          need_state: "health",
          choice_driver: "gamer",
          city: "london",
          qr: [
            {
              type: "video",
              file: "CokeCan_65276042_QR4_Gaming_London.mp4",
            },
          ],
        },
      ],
    },
    {
      cityId: "singapore",
      lat: 1.3143394,
      long: 103.704165,
      occasions: [
        {
          occasion_name: "watching an e-sport game",
          occasion_title: "Social eSports",
          occasion_index: 91,
          context: "leisure",
          need_state: "enjoy",
          choice_driver: "gamer",
          city: "singapore",
          qr: [
            {
              type: "video",
              file: "CokeCan_65276385_QR3_Gaming_SINGAPORE.mp4",
            },
          ],
        },
      ],
    },
  ],
};

export const occasionDictionary = {
  workout: "Early Workout",
  breakfast: "Healthy Work Breakfast",
  "on the move": "Commute Pick Me Up",
  "eating out": "Social Dinner Out",
  studying: "Evening Study Break",
  working: "WFH Break",
  "pause at work": "Healthy Afternoon Snack",
  "social media": "Social Scrolling",
  "going out": "Team Sports",
  "watching a movie": "Movie Night",
  "playing video games online": "Social Gaming",
  diner: "Easy Solo Dinner",
  socialising: "Brunch with Friends",
  "binge watching series": "Screentime Unwind",
  gaming: "Gaming Break",
  "snack time": "Afternoon Pick Me Up",
  "pause at": "Morning Study Break",
  "watching an e-sport game": "Social eSports",
  "me time": "Solo Shopping",
  "commute to university": "Eating On the Go",
  "commute to home": "On the Way Home",
  "live event": "Gig Night Out",
  lunch: "Everyday Lunch Together",
  "snack at university": "After School Treat",
};
