import { _GlobeView, Deck } from "@deck.gl/core";
import { BASEMAP } from "@deck.gl/carto";

import { hexagonLayerFilter } from "@/deck/h3";

export const createDeck = (context, viewStateUpdate, deckProps, additionalLayerFilter) => {
  const layerFilter = ({ layer, viewport }) => {
    const result = additionalLayerFilter({ layer, viewport });
    if (!result) {
      return result;
    }
    return hexagonLayerFilter({ layer, viewport });
  };

  return new Deck({
    views: new _GlobeView(),
    useDevicePixels: false,
    width: "100%",
    height: "100%",
    canvas: context.$refs.sphereMap,
    mapStyle: BASEMAP.DARK_MATTER_NOLABELS,
    initialViewState: context.viewState,
    controller: { doubleClickZoom: false },
    getCursor: ({ isHovering, isDragging }) => {
      if (isHovering) {
        return "pointer";
      }

      return isDragging ? "grabbing" : "grab";
    },
    _animate: true,
    layerFilter,
    ...deckProps,

    onViewStateChange: ({ viewState }) => {
      context.setIsUserStopAnimation(true);
      context.setViewState(viewState);
      context.deckGl?.setProps({ viewState });
      viewStateUpdate(viewState);
    },

    onLoad: () => {
      console.log("Map loaded.");
      context.onMapLoad();
    },

    onClick: (info) => {
      context.points = context.points || [];
      context.points.push({ long: info.coordinate[0], lat: info.coordinate[1] });
      console.log("CLICKED POINTS", context.points);
    },
  });
};
