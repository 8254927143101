<template>
  <div
    style="width: 100%; height: 100%; display: flex; justify-content: center; align-items: center"
    class="clickable city-container"
  >
    <div class="city-occasions">
      <div class="city-occasions__header">
        <span class="city-occasions__header__region"> {{ regionName }} </span>
        <span class="city-occasions__header__date">{{ getDate }}</span>
      </div>

      <div class="city-occasions__title-wrapper">
        <div class="city-occasions__title">{{ selectedOccasion.occasion.name }}</div>
        <img :src="require('@/assets/images/spotify.svg')" alt="Spotify" />
      </div>

      <div class="image-block">
        <component :is="image.component" v-for="image in images" :key="image.alt" style="display: block"></component>
      </div>
    </div>
    <v-btn class="go-back-btn clickable" @click="onGoBack" fixed icon large color="#fff">
      <v-icon size="36">mdi-chevron-left</v-icon>
    </v-btn>
    <router-link class="go-home-btn" :to="{ name: 'Occasions' }" @click.native="setSpotifyMode(false)">
      <img :src="require('@/assets/images/choreograph.svg')" alt="Choreograph" />
    </router-link>
  </div>
</template>

<script>
import { mapMutations, mapGetters } from "vuex";
import { getDateString } from "@/utils/dateTime";
import { sliderOccasionIconMapping } from "@/assets/icons/occasions-sprite";
import cityImageDictionary from "@/mocks/cityImageDictionary";
import AtlantaMusic from "@/components/svg/AtlantaMusic";
import AtlantaPodcast from "@/components/svg/AtlantaPodcast";
import SingaporeMusic from "@/components/svg/SingaporeMusic";
import SingaporePodcast from "@/components/svg/SingaporePodcast";

export default {
  name: "SpotifyCity",
  components: {
    AtlantaMusic,
    AtlantaPodcast,
    SingaporeMusic,
    SingaporePodcast,
  },

  data() {
    return {
      occasions: {
        context: { title: "context", key: "context" },
        needState: { title: "need", key: "need_state" },
        choiceDriver: { title: "passion", key: "choice_driver" },
      },
    };
  },

  created() {
    this.setGlobeMap(false);
    if (
      !this.selectedOccasion?.occasion?.cityId ||
      !this.selectedOccasion?.occasion?.points.length ||
      !this.selectedOccasion?.occasion?.occasions.length
    ) {
      this.$router.replace("/");
    }
  },

  beforeDestroy() {
    this.changeIsInitialLoading(true);
  },

  computed: {
    ...mapGetters("occasions", ["selectedOccasion", "activeSlide"]),

    getDate() {
      return getDateString();
    },

    regionName() {
      return this.selectedOccasion?.occasion?.region?.toUpperCase();
    },

    images() {
      if (!cityImageDictionary[this.selectedOccasion.occasion.cityId]) {
        return cityImageDictionary.default;
      }
      return cityImageDictionary[this.selectedOccasion.occasion.cityId];
    },
  },

  methods: {
    ...mapMutations("app", ["setGlobeMap", "changeIsInitialLoading", "setSpotifyMode"]),

    onGoBack() {
      this.$router.go(-1);
    },

    getIcon(occasionName) {
      // TODO: It is necessary to set an icon even the occasion icon is not found. It is "workout" so far.
      return sliderOccasionIconMapping[occasionName]?.toLowerCase() || "workout";
    },
  },
};
</script>

<style scoped lang="scss">
.city-occasions {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border: 1px solid #ffffff36;
  color: white;
  backdrop-filter: blur(8px);
  background-color: rgba(0, 0, 0, 0.5);

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 42px 36px 0;

    &__region {
      font-size: 36px;
      font-weight: 400;
    }

    &__date {
      font-size: 24px;
      font-weight: 400;
    }
  }

  &__title-wrapper {
    display: flex;
    margin: -12px 36px 10px 36px;
  }

  &__title {
    flex-grow: 1;
    font-weight: 300;
    font-size: 104px;
  }

  .image-block {
    width: 912px;
    color: white;
    padding: 0 36px 32px 36px;

    img {
      width: 100%;
      display: block;
    }
  }
}

.go-back-btn {
  width: 80px !important;
  height: 80px !important;
  margin-left: 24px;
  border: 1px solid #fff;
  border-radius: 0;
  position: absolute;
  left: 0;
}

.go-home-btn {
  position: absolute;
  bottom: 30px;
  left: 30px;
  img {
    height: 50px;
  }
}
</style>
