<template>
  <div>
    <div class="data-sources-nav clickable" @click="sheet = !sheet">
      <span>Data Sources</span>
      <v-icon color="#fff" large class="data-sources-nav__icon">mdi-chevron-up</v-icon>
    </div>
    <v-bottom-sheet
      v-model="sheet"
      :internal-activator="true"
      overlay-color="#21212B32"
      overlay-opacity="1"
      content-class="data-sources-modal"
    >
      <div class="data-sources-modal__btn-container">
        <div class="data-sources-modal__btn" @click="sheet = !sheet">
          <span>Data Sources</span>
          <v-icon color="#fff" large class="data-sources-nav__icon">mdi-chevron-down</v-icon>
        </div>
      </div>

      <div class="data-sources-modal__content-wrapper">
        <div class="data-sources-modal__content">
          <div class="data-sources-modal__block-wrapper left">
            <div class="data-sources-modal__block-title">Country, Annual</div>
            <div class="data-sources-modal__icons-wrapper">
              <div class="data-sources-modal__icon-block left-top">
                <img :src="require('@/assets/icons/data-sources/ico_cartoo.png')" alt="Carto" />
              </div>
              <div class="data-sources-modal__icon-block right-top">
                <img :src="require('@/assets/icons/data-sources/ico_mapster.png')" alt="Mapster" />
              </div>
              <div class="data-sources-modal__icon-block left-bottom">
                <img :src="require('@/assets/icons/data-sources/ico_experian.png')" alt="Experian" />
              </div>
              <div class="data-sources-modal__icon-block" style="display: flex; align-items: center">
                <img :src="require('@/assets/icons/data-sources/ico_bavgroup.png')" alt="Bavgroup" />
              </div>
            </div>
          </div>
          <div class="data-sources-modal__block-wrapper center">
            <div class="data-sources-modal__block-title">City, Daily-Quarterly</div>
            <div class="data-sources-modal__icons-wrapper">
              <div class="data-sources-modal__icon-block left-top">
                <img :src="require('@/assets/icons/data-sources/ico_google.png')" alt="Google" />
              </div>
              <div class="data-sources-modal__icon-block right-top">
                <img :src="require('@/assets/icons/data-sources/ico_twitter.png')" alt="Twitter" />
              </div>
              <div class="data-sources-modal__icon-block left-bottom">
                <button @click="onSpotifyClick">
                  <img :src="require('@/assets/icons/data-sources/ico_spotify.png')" alt="Spotify" width="100" />
                </button>
              </div>
              <div class="data-sources-modal__icon-block" style="display: flex; align-items: center">
                <img :src="require('@/assets/icons/data-sources/ico_nielsen.png')" alt="Nielsen" />
              </div>
            </div>
          </div>
          <div class="data-sources-modal__block-wrapper right">
            <div class="data-sources-modal__block-title">Lat Long, Hourly</div>
            <div class="data-sources-modal__icons-wrapper">
              <div class="data-sources-modal__icon-block left-top">
                <img :src="require('@/assets/icons/data-sources/ico_predicthq.png')" alt="Predicthq" />
              </div>
              <div class="data-sources-modal__icon-block right-top">
                <img :src="require('@/assets/icons/data-sources/ico_ambee.png')" alt="Ambee" />
              </div>
              <div class="data-sources-modal__icon-block left-bottom">
                <img :src="require('@/assets/icons/data-sources/ico_safegraph.png')" alt="Safegraph" />
              </div>
              <div class="data-sources-modal__icon-block" style="display: flex; align-items: center">
                <img :src="require('@/assets/icons/data-sources/ico_google-ads.png')" alt="Google-ads" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </v-bottom-sheet>
  </div>
</template>

<script>
import { mapMutations } from "vuex";

export default {
  name: "DataSources",

  data() {
    return {
      sheet: false,
    };
  },

  methods: {
    ...mapMutations("app", ["setSpotifyMode"]),

    onSpotifyClick() {
      this.setSpotifyMode(true);
      this.sheet = false;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../scss/style";

.data-sources-nav {
  width: 260px;
  height: 54px;
  -webkit-backdrop-filter: blur(4px);
  backdrop-filter: blur(4px);
  background: #262626f2;
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: flex-end;
  font-size: 24px;
  text-transform: uppercase;
  margin: 0 auto;
  color: #fff;

  &__icon {
    margin-left: 6px;
  }
}
</style>

<style lang="scss">
.v-overlay__scrim {
  -webkit-backdrop-filter: blur(15px);
  backdrop-filter: blur(15px);
}

.data-sources-modal {
  position: relative;

  &__btn-container {
    display: flex;
    justify-content: center;
    align-items: center;
    align-self: flex-end;
    position: absolute;
    top: -32px;
    left: 0;
  }

  &__btn {
    position: relative;
    background: rgba(255, 255, 255, 0.12);
    backdrop-filter: blur(48px);
    height: 64px;
    width: 266px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 24px;
    text-transform: uppercase;
    margin: 0 auto;
    color: #fff;
  }

  &__content-wrapper {
    background: rgba(36, 36, 43, 0.92);
    height: 398px;
  }

  &__content {
    display: grid;
    grid-template-columns: 32% 36% 32%;
    grid-template-rows: 100%;
    height: 100%;
    width: 100%;
    max-width: 100%;
  }

  &__sources-wrapper {
    display: flex;
    justify-content: space-between;
    max-width: 100%;
    flex-shrink: 0;
  }

  &__block-wrapper {
    align-items: center;
    display: flex;
    flex-direction: column;
    padding-top: 98px;

    &.left {
      padding-left: 15%;
      padding-right: 25%;
      border-right: 1px solid rgba(255, 255, 255, 0.12);
    }

    &.center {
      padding-left: 23%;
      padding-right: 23%;
    }

    &.right {
      padding-left: 25%;
      padding-right: 15%;
      border-left: 1px solid rgba(255, 255, 255, 0.12);
    }
  }

  &__block-title {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 266px;
    height: 44px;
    color: #fff;
    background: rgba(255, 255, 255, 0.12);
  }

  &__icons-wrapper {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    grid-gap: 1px;
  }

  &__icon-block {
    display: flex;
    align-items: center;
    justify-content: center;

    &.left-top {
      border-right: 1px solid rgba(255, 255, 255, 0.12);
      border-bottom: 1px solid rgba(255, 255, 255, 0.12);
    }

    &.right-top {
      border-bottom: 1px solid rgba(255, 255, 255, 0.12);
    }

    &.left-bottom {
      border-right: 1px solid rgba(255, 255, 255, 0.12);
    }
  }
}
</style>
