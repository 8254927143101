import { API_VERSIONS, MAP_TYPES, setDefaultCredentials, getData } from "@deck.gl/carto";

// TODO: Remove this instruction when getPredefinedCitiesDetails is started to use.
// eslint-disable-next-line no-unused-vars
import { getPredefinedCitiesDict, getPredefinedCitiesOccasions, getPredefinedCitiesDetails } from "@/mocks/cities";
import { getPredefinedOccasionNames, getPredefinedOccasionCategories } from "@/mocks/occasions";

const USE_DATA_FROM_DATABASE = false;

const TOKEN_REFRESH_URL = "https://cannes-demo.steppechange.com/carto/token";
const MAX_CARTO_DATA_ATTEMPTS = 3;
const NUM_OCCASIONS_DEFAULT = 7;

/**
 * Store the current CARTO token.
 * Note: At the current solution we are not going to use the predefined token,
 * i.e. we always refresh it on the first data request. If we will need to use the default one,
 * we can restore the following string.
 * ```
 * let currentToken = process.env.VUE_APP_CARTO_ACCESS_TOKEN;
 * ```
 * @type string
 */
let currentToken;

export const setCartoDefaultCredentials = (token) =>
  setDefaultCredentials({
    apiBaseUrl: "https://gcp-us-east1.api.carto.com",
    apiVersion: API_VERSIONS.V3,
    accessToken: token || currentToken,
  });

export const refreshToken = async () => {
  return fetch(TOKEN_REFRESH_URL)
    .then(async (response) => {
      if (!response.ok) {
        throw response;
      }

      const { token } = await response.json();

      if (!token) {
        throw "ERROR: Refresh token service did not return a token.";
      }

      setCartoDefaultCredentials(token);
      currentToken = token;
      console.log("CARTO token updated:", currentToken);

      return token;
    })
    .catch((error) => {
      console.log(`ERROR: Cannot refresh CARTO token, status: ${error.status}:`, error);
      return null;
    });
};

const checkToken = async () => {
  if (!currentToken) {
    return refreshToken();
  }
};

/**
 * Default point is Cannes.
 * @type {{ long: number, lat: number, zoom: number, pitch: number, bearing: number }}
 */
export const defaultCityPoint = {
  long: 7.01388,
  lat: 43.55125,
  zoom: 10,
  pitch: 0,
  bearing: 0,
};

const getCartoData = async (params, attempt = 1) => {
  await checkToken();

  return getData(params)
    .then(async (response) => {
      return response;
    })
    .then((response) => response)
    .catch(async (error) => {
      console.log(`Cannot get CARTO data, status: ${error.status}:`, error);

      if (attempt < MAX_CARTO_DATA_ATTEMPTS) {
        await refreshToken();

        ++attempt;
        console.log(`Repeat the CARTO data request, attempt ${attempt}.`);
        return getCartoData(params, attempt);
      } else {
        console.log(
          `Cannot get CARTO data, maximum CARTO data request repetitions (${attempt} from ${MAX_CARTO_DATA_ATTEMPTS}) was reached.`
        );
      }
    });
};

export const getCitiesDict = getPredefinedCitiesDict;
export const getOccasionNames = getPredefinedOccasionNames;
export const getOccasionCategories = getPredefinedOccasionCategories;

export const getCitiesData = async () =>
  getCartoData({
    type: MAP_TYPES.QUERY,
    source: "SELECT * FROM geograph-342112.cannes_demo.global_cities ORDER BY population DESC LIMIT 200",
    connection: "cannes",
    format: "json",
  });

export const getCityH3Data = async (cityId) =>
  getCartoData({
    type: MAP_TYPES.QUERY,
    source: `SELECT name, h3index, index FROM geograph-342112.cannes_demo.cities_1 where name="${cityId}"`,
    connection: "cannes",
    format: "json",
  });

export const getCityDemoData = async (cityId, isPoints = true, numOccasions = NUM_OCCASIONS_DEFAULT) => {
  await checkToken();

  // TODO: VL: Decide what to do with that.
  const occasionNames = await getOccasionNames();
  const inOccasions = `("${occasionNames.join('","')}")`;

  const dataRequests = [
    () =>
      getCartoData({
        type: MAP_TYPES.QUERY,
        source: `SELECT * FROM pitchwork-319916.Geograph.carto_occasions 
                 WHERE city="${cityId}" 
                 AND occasion_name IN ${inOccasions}
                 ORDER BY occasion_index 
                 DESC LIMIT ${numOccasions}`,
        connection: "cannes",
        format: "json",
      }),
  ];

  if (isPoints) {
    dataRequests.push(() =>
      getCartoData({
        type: MAP_TYPES.QUERY,
        source: `SELECT map FROM pitchwork-319916.Geograph.carto_map_sync WHERE city="${cityId}" LIMIT 1`,
        connection: "cannes",
        format: "json",
      })
    );
  }

  const result = await Promise.all(dataRequests.map((dataRequest) => dataRequest()));

  const occasions = result?.[0] || [];
  const mapRecords = result[1]?.[0]?.map || [];

  const points = mapRecords.map((arrStr) => {
    try {
      const arr = JSON.parse(arrStr);

      return {
        long: arr?.[0] || defaultCityPoint.long,
        lat: arr?.[1] || defaultCityPoint.lat,
        zoom: arr?.[2] || defaultCityPoint.zoom,
        pitch: arr?.[3] || defaultCityPoint.pitch,
        bearing: arr?.[4] || defaultCityPoint.bearing,
      };
    } catch (e) {
      console.log("ERROR (CITY MAP POINTS):", e);
      return defaultCityPoint;
    }
  });

  const citiesDict = await getCitiesDict();

  return {
    ...citiesDict[cityId],
    cityId: cityId,
    occasions,
    points,
  };
};

export const getCitiesDemoData = async (numOccasions = NUM_OCCASIONS_DEFAULT) => {
  if (!USE_DATA_FROM_DATABASE) {
    return getPredefinedCitiesOccasions();
  }

  await checkToken();

  const citiesDict = await getCitiesDict();
  const cityIds = Object.keys(citiesDict);

  return Promise.all(cityIds.map((cityId) => getCityDemoData(cityId, numOccasions)));
};

export const getCitySocialData = async (cityId) =>
  getCartoData({
    type: MAP_TYPES.QUERY,
    source: `SELECT object, sentiment_magnitude, sentiment_direction 
             FROM pitchwork-319916.Geograph.carto_matrix \
             WHERE city="${cityId}" 
               AND object IS NOT NULL 
               AND sentiment_magnitude IS NOT NULL 
               AND sentiment_direction IS NOT NULL LIMIT 1`,
    connection: "cannes",
    format: "json",
  });

export const getCitySearchData = async (cityId) =>
  getCartoData({
    type: MAP_TYPES.QUERY,
    source: `SELECT search FROM pitchwork-319916.Geograph.carto_matrix 
             WHERE city="${cityId}" AND search IS NOT NULL LIMIT 1`,
    connection: "cannes",
    format: "json",
  });

export const getCityEventsData = async (cityId) =>
  getCartoData({
    type: MAP_TYPES.QUERY,
    source: `SELECT local_events FROM pitchwork-319916.Geograph.carto_matrix 
             WHERE city="${cityId}" AND local_events IS NOT NULL LIMIT 1`,
    connection: "cannes",
    format: "json",
  });

export const getCityWeatherData = async (cityId) =>
  getCartoData({
    type: MAP_TYPES.QUERY,
    source: `SELECT precip, wind_spd, max_temp_f, max_temp_c, min_temp_f, min_temp_c 
             FROM pitchwork-319916.Geograph.carto_matrix WHERE city="${cityId}" LIMIT 1`,
    connection: "cannes",
    format: "json",
  });

export const convertCitySocialData = (rawData) => rawData?.[0] || {};

export const convertCityEventsData = (rawData) => {
  try {
    // Descending sort by the rank.
    return JSON.parse(rawData?.[0].local_events).sort((a, b) => (a.rank > b.rank ? -1 : +1));
  } catch (e) {
    console.log("ERROR (CITY EVENTS):", e);
    return [];
  }
};

export const convertCitySearchData = (rawData) => {
  try {
    // Descending sort by the score.
    return JSON.parse(rawData?.[0].search).sort((a, b) => (a.score > b.score ? -1 : +1));
  } catch (e) {
    console.log("ERROR (CITY SEARCH):", e);
    return [];
  }
};

export const convertCityWeatherData = (rawData) => rawData?.[0] || {};

export const getCityDetailsData = async (cityId, occasionName) => {
  // occasionName can only be used for the predefined data set so far.
  // For now it is not clear how to consider it when the database request is used.
  if (!USE_DATA_FROM_DATABASE) {
    const allCitiesDetails = await getPredefinedCitiesDetails();
    return allCitiesDetails?.[cityId]?.[occasionName] || {};
  }

  await checkToken();

  const rawResult = await Promise.all([
    getCitySocialData(cityId),
    getCityEventsData(cityId),
    getCitySearchData(cityId),
    getCityWeatherData(cityId),
  ]);

  return {
    social: convertCitySocialData(rawResult?.[0]),
    events: convertCityEventsData(rawResult?.[1]), // Important for us: title, rank.
    search: convertCitySearchData(rawResult?.[2]), // Important for us: term, score.
    weather: convertCityWeatherData(rawResult?.[3]),
  };
};
