export const filterOccasion = (data, filter) => {
  if (!data) {
    return [];
  }

  if (filter[0] === "all") {
    filter = data.filter((el) => el !== "all");
  }

  const filterPrepared = filter.map((item) => item.toLowerCase());

  return data.filter((el) => {
    return filterPrepared.includes(el.toLowerCase());
  });
};

export const filterDataOccasions = (occasionPoints, filters) => {
  return occasionPoints.filter((occasionPoint) => {
    return occasionPoint.occasions.find((occasion) => {
      return (
        (filters.context.length === 0 || filters.context.includes(occasion.context.toLowerCase())) &&
        (filters.needStates.length === 0 || filters.needStates.includes(occasion.need_state.toLowerCase())) &&
        (filters.choiceDrivers.length === 0 || filters.choiceDrivers.includes(occasion.choice_driver.toLowerCase()))
      );
    });
  });
};
