<template>
  <vc-donut
    class="occasion-index__chart"
    :background="donutBackgroundColor"
    foreground="#737b84"
    :size="donutSize"
    :thickness="donutThickness"
    :sections="[{ value: occasionIndex, color: 'white' }]"
    >{{ occasionIndex }}
  </vc-donut>
</template>

<script>
export default {
  name: "Donut",
  props: {
    occasionIndex: Number,
    donutSize: {
      type: Number,
      default: 40,
    },
    donutThickness: {
      type: Number,
      default: 35,
    },
    donutBackgroundColor: {
      type: String,
      default: "#8895a2",
    },
  },
};
</script>

<style></style>
