<template>
  <v-tooltip v-model="showToolTip" bottom :open-on-hover="true" nudge-top="8">
    <template v-slot:activator="{ on, attrs }">
      <img
        @click="handleClick"
        class="vendor-icon"
        :src="require(`@/assets/icons/compose/${iconSrc}`)"
        alt=""
        v-on="on"
        v-bind="attrs"
      />
    </template>
    <span>{{ iconText }}</span>
  </v-tooltip>
</template>

<script>
export default {
  name: "IconTooltip",
  props: {
    iconSrc: String,
    iconText: String,
  },
  data() {
    return {
      showToolTip: false,
    };
  },
  methods: {
    handleClick() {
      this.showToolTip = true;

      setTimeout(() => {
        this.showToolTip = false;
      }, 1500);
    },
  },
};
</script>

<style lang="scss" scoped>
.vendor-icon {
  height: 48px;
  margin-left: 16px;
}
.v-tooltip__content.menuable__content__active {
  opacity: 1;
}
.v-tooltip__content {
  span {
    letter-spacing: 0.08em;
  }
}
</style>
