<template>
  <div
    class="content loading-page"
    :style="{ 'background-image': `url(${require('@/assets/images/stars-foff.jpg')})` }"
  >
    <img v-if="isStatic" class="logo" :src="require('@/assets/images/choreograph.svg')" alt="Choreograph" />

    <div v-else class="logo-animation">
      <div class="frame-choreograph">
        <img src="@/assets/logo/logo-choreograph-exact.svg" alt="Choreograph" />
      </div>
      <div class="frame-occasions">
        <img src="@/assets/logo/logo-occasions-graph-exact.svg" alt="Choreograph" />
      </div>
      <div class="frame-compose">
        <img src="@/assets/logo/logo-compose-exact.svg" alt="Choreograph" />
      </div>
      <div class="frame-sync">
        <img src="@/assets/logo/logo-sync-exact.svg" alt="Choreograph" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "LoadingC9h",

  data() {
    return {
      isStatic: false,
    };
  },
};
</script>

<style lang="scss" scoped>
@import "../scss/style";

$one-step-time: 500ms;
$frame-height: 203px;
$frame-height-half: 0.5 * $frame-height;
$top-end: -$frame-height-half;

.loading-page {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10;
  background-size: 100% auto;
  background-repeat: no-repeat;
  background-position: center;

  .logo-animation {
    --top-base: calc(50% - #{$frame-height-half});

    display: flex;
    justify-content: center;
    width: 100%;
    height: 100%;
    position: relative;

    [class^="frame-"] {
      position: absolute;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 506px;
      height: 203px;

      top: var(--top-base);
      opacity: 0;

      animation-name: frame-one;
      animation-duration: 4 * $one-step-time;
      animation-timing-function: ease;
      animation-iteration-count: infinite;
      animation-direction: normal;
      animation-fill-mode: forwards;
    }

    .frame-choreograph {
      animation-delay: 0s;
    }

    .frame-occasions {
      animation-delay: $one-step-time;
    }

    .frame-compose {
      animation-delay: 2 * $one-step-time;
    }

    .frame-sync {
      animation-delay: 3 * $one-step-time;
    }
  }
}

.logo {
  height: 202px;
}

@keyframes frame-one {
  0% {
    display: flex;
    top: var(--top-base);
    opacity: 0;
  }
  5% {
    display: flex;
    top: var(--top-base);
    opacity: 1;
  }
  25% {
    display: flex;
    top: var(--top-base);
    opacity: 1;
  }
  40% {
    display: flex;
    top: $top-end;
    opacity: 0;
  }
}
</style>
