import { getRandomElement } from "@/utils/random";

export const occasionColor = [255, 255, 255];
export const occasionTransitionDuration = 1500;

export const occasionPlatePoints = [
  { lat: 15.707816420024283, long: -10.251960695588881 },
  { lat: -6.607881693076811, long: 22.50539396836489 },
  { long: 44.44690122373133, lat: 23.21794369681113 },
  { lat: 50.976012731273336, long: 52.357453757153166 },
  { lat: 15.524352925932652, long: 55.756278709801734 },
  { lat: 28.914407018762276, long: 91.24502418103502 },
  { lat: 43.97952716877744, long: 110.31442827466702 },
  { lat: 3.803916277269707, long: 96.55492635912596 },
  { lat: -18.26068346752654, long: 123.56379555260307 },
  { lat: -4.795016770868253, long: 139.84284369344354 },
  { lat: 27.57368078207414, long: 137.84415393611732 },
  { lat: 40.18713946289195, long: -116.37882951061455 },
  { lat: 25.48857502058854, long: -90.0759839771066 },
  { lat: 6.287443069190755, long: -82.04925342581798 },
  { lat: -17.775968618355446, long: -70.0726033295748 },
  { lat: -5.113418776282621, long: -42.678287545920796 },
  { lat: -32.42882040756401, long: -68.3876511928453 },
  { lat: 41.262532707479835, long: -55.502820857034216 },
  { lat: -2.9199435026466896, long: 23.05850618026054 },
  { lat: -14.188400218491793, long: 46.89395562407766 },
];

export const occasionSettings = {
  startOpacity: 0,
  endOpacity: 255,
  stepOpacity: 3,
  delay: 5000,
  initialColor: [255, 255, 255],
  offsetLat: 30,
  offsetLong: 20,
};

export const occasionPlateSettings = {
  longOffsetFrom: 60,
  longOffsetTo: 20,
  latOffsetFrom: 30,
  latOffsetTo: 60,
  startOpacity: 0,
  endOpacity: 255,
  stepOpacity: 1,
  delay: 5000,
  initialColor: [255, 255, 255],
  numberPoints: 2,
  zCoordinate: 3000000,
};

// This is a stub for future development, we will need it if we decide to change Z dynamically for plates.
export const getOccasionPlateZ = () => occasionPlateSettings.zCoordinate;

const OCCASIONS_INDEX_TOP_DELTA = 5;
export const calcTopIndex = (preTopValue) => {
  const topValue = preTopValue + OCCASIONS_INDEX_TOP_DELTA;
  return topValue > 100 ? 100 : topValue;
};

export const getAllOccasions = (data) => {
  let occasions = [];

  Object.values(data).forEach((city) => {
    occasions = [...occasions, ...city.occasions];
  });

  return occasions;
};

export const buildOccasionPlateData = (filter, data) => {
  const occasions = getAllOccasions(data);

  const occasionsFiltered = occasions.filter((occasion) => {
    return (
      filter.contexts.includes(occasion.context?.toLowerCase()) &&
      filter.needStates.includes(occasion.need_state?.toLowerCase()) &&
      filter.choiceDrivers.includes(occasion.choice_driver?.toLowerCase())
    );
  });

  return getRandomElement(occasionsFiltered);
};

export const getRandomPlateData = (occasionCategories) => {
  const context = occasionCategories.contexts.filter((el) => el !== "all");
  const needStates = occasionCategories.needStates.filter((el) => el !== "all");
  const choiceDrivers = occasionCategories.choiceDrivers.filter((el) => el !== "all");
  return {
    context: getRandomElement(context),
    needStates: getRandomElement(needStates),
    choiceDrivers: getRandomElement(choiceDrivers),
  };
};
