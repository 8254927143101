<template>
  <v-app>
    <div
      class="content login-container"
      :style="{ 'background-image': `url(${require('@/assets/images/stars-foff.jpg')})` }"
    >
      <v-form class="login-form" autocomplete="new-password">
        <v-text-field
          v-model.trim="username"
          dark
          class="mb-0"
          hide-details="auto"
          type="text"
          placeholder="Login"
          name="username"
          :rules="[rules.required]"
          :error="!!this['user/loginError']"
        ></v-text-field>
        <v-text-field
          v-model="password"
          dark
          hide-details="auto"
          type="password"
          name="password"
          placeholder="Password"
          autocomplete="new-password"
          :rules="[rules.required]"
          :error="!!this['user/loginError']"
        ></v-text-field>
        <div v-if="!!this['user/loginError']" class="error--text">
          Email address/password do not match any existing accounts.
        </div>
        <div class="login-button-container pt-2">
          <v-btn outlined :disabled="!isFormDataEntered" @click="onLoginClick">Login</v-btn>
        </div>
      </v-form>
    </div>
  </v-app>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";

export default {
  name: "Loading",

  data() {
    return {
      username: "",
      password: "",

      rules: {
        required: (value) => !!value || "Please enter a value.",
      },
    };
  },

  computed: {
    ...mapGetters(["user/loginError"]),

    isFormDataEntered: function () {
      return this.username && this.password;
    },
  },

  methods: {
    ...mapGetters(["user/authorized"]),
    ...mapMutations(["user/setLoginError"]),
    ...mapActions(["user/login"]),

    onLoginClick: async function () {
      await this["user/login"]({
        username: this.username,
        password: this.password,
      });

      if (this["user/authorized"]()) {
        await this.$router.push("/");
      }
    },
  },

  watch: {
    username: function () {
      this["user/setLoginError"]("");
    },
    password: function () {
      this["user/setLoginError"]("");
    },
  },
};
</script>

<style lang="scss" scoped>
@import "./src/scss/style";

.login-container {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10;
  background-size: 100% auto;
  background-repeat: no-repeat;
  background-position: center;

  .login-form {
    max-width: 280px;

    .login-button-container {
      text-align: right;
    }
  }

  ::v-deep {
    .theme--dark.v-input input {
    }
    .v-text-field {
      margin-bottom: 24px;

      .v-label--active {
        transform-origin: 28px;
      }
    }

    .v-input {
      font-size: 1.5em;

      input {
        max-height: none;
        padding: 8px;

        /* This string fixes the autofill background. */
        color-scheme: dark;
      }
    }
  }
}
</style>
