export const REDRAWING_TIME = 20;
export const EARTH_RADIUS_METERS = 6.3e6;

export const globeSettings = {
  rotateStep: 0.1,
};

export const globeInitialViewState = {
  latitude: 5,
  longitude: 7.017369,
  zoom: 0.5,
  minZoom: 0.5,
};
