import { CompositeLayer } from "@deck.gl/core";
import { IconLayer } from "@deck.gl/layers";

import { getCitiesDict } from "@/deck/carto";
import { getOccasionPlateZ } from "@/helpers/occasions";
import { createPlateTextLayer } from "@/components/layers/PlateTextLayer";
import { createPlateIconLayer } from "@/components/layers/PlateIconLayer";
import { plateCityBgMapping } from "@/assets/icons/occasions-sprite";

export const createCityPlateLayer = async (ctx) => {
  const citiesDict = await getCitiesDict();
  const isSpotifyMode = ctx.$store.getters["app/isSpotifyMode"];

  class CityPlateLayer extends CompositeLayer {
    renderLayers() {
      return [
        new IconLayer({
          id: "background",
          data: this.props.data,
          getPosition: this.props.getPosition,
          pickable: true,
          opacity: 1,
          iconAtlas: require("/src/assets/icons/plate-city-bg.svg"),
          iconMapping: plateCityBgMapping,
          getIcon: () => "background",
          billboard: true,
          sizeScale: 4,
          getSize: () => 36,
          getPixelOffset: [0, 0],
        }),

        ...createPlateIconLayer({ ctx: this, id: "occasion", offset: [0, 28], isSpotifyMode }),
        ...createPlateIconLayer({
          ctx: this,
          id: "firstAdditionalOccasion",
          index: 1,
          offset: [0, 60],
          opacity: 0.1,
          isSpotifyMode,
        }),

        ...createPlateIconLayer({
          ctx: this,
          id: "secondAdditionalOccasion",
          index: 2,
          offset: [0, 90],
          opacity: 0.1,
          isSpotifyMode,
        }),

        createPlateTextLayer({ ctx: this, id: "titleOccasion", size: 18, offset: [0, 0] }),
        createPlateTextLayer({ ctx: this, id: "titleTime", text: "getTextTime", size: 12, offset: [270, 0] }),
      ];
    }
  }

  CityPlateLayer.layerName = "CityPlateLayer";

  CityPlateLayer.defaultProps = {
    fontFamily: "Urbanist, sans-serif",
    getTextAnchor: "start",
    getAlignmentBaseline: "center",
    billboard: true,
    pickable: true,

    getPosition: {
      type: "accessor",
      value: (d) => [d.long, d.lat, getOccasionPlateZ()],
    },

    getText: {
      type: "accessor",
      value: (d) => d.name?.toUpperCase(),
    },

    getIcon: {
      type: "accessor",
      value: () => ctx.visibleCities,
    },

    getColor: {
      type: "accessor",
      value: () => [255, 255, 255],
    },

    getShowNow: {
      type: "accessor",
      value: () => true,
    },

    getTextTime: {
      type: "accessor",

      value: (d) => {
        const time = new Date().toLocaleTimeString(navigator.language, {
          hour: "numeric",
          minute: "numeric",
          hour12: true,
          timeZone: citiesDict[d.cityId]?.tz,
        });
        return time.split(" ")[0];
      },
    },
  };

  return (layerCtx, _animationTick) => {
    return new CityPlateLayer({
      id: "city-plate-layer",
      data: layerCtx.visibleCities,

      updateTriggers: {
        getColor: [_animationTick],
        getText: [_animationTick],
        getPosition: [_animationTick],
        all: [_animationTick],
      },

      onClick: (point) => {
        layerCtx.setSelectedOccasion(point.object);
        // Here we specify the transition after choosing a city.
        layerCtx.$router.push({ path: isSpotifyMode ? "/spotifyCity" : "/city" });
      },
    });
  };
};
