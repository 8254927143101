<template>
  <div class="info-container not-clickable">
    <v-btn class="go-back-btn clickable" @click="onGoBack" fixed icon large color="#fff">
      <v-icon size="36">mdi-chevron-left</v-icon>
    </v-btn>

    <div class="info-header">
      <div class="info-header__left">
        <div class="clickable">Moments</div>
      </div>
      <div class="info-header_center"></div>
      <div class="info-header__right">
        <div class="clickable">{{ getDate }}</div>
      </div>
    </div>

    <div class="info-footer">
      <div class="info-footer__left">
        <router-link :to="{ name: 'Occasions' }" @click.native="setSpotifyMode(false)" class="clickable">
          <img class="logo" :src="require('@/assets/images/choreograph.svg')" alt="Choreograph" />
        </router-link>
      </div>
      <div class="info-footer__center"></div>
      <div class="info-footer__right"></div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";

export default {
  name: "CityChoices",
  data() {
    return {};
  },

  mounted() {
    if (this.visibleCities.length === 0) {
      this.$router.replace("/");
    }

    this.setGlobeMap(true);
    this.showPlateCities();
  },

  computed: {
    ...mapGetters("mainGlobe", ["isUserStopAnimation", "visibleCities"]),
    getDate() {
      const today = new Date();
      const month = today.toLocaleString("en-US", { month: "long" });
      const day = today.getDate();
      const year = today.getFullYear();
      return day + " " + month + " " + year;
    },
  },

  methods: {
    ...mapMutations("mainGlobe", ["showPlateCities", "showPlateOccasions"]),
    ...mapMutations("app", ["setGlobeMap", "setSpotifyMode"]),

    onGoBack() {
      this.showPlateOccasions();
      this.$router.go(-1);
    },
  },
};
</script>
