import { TextLayer } from "@deck.gl/layers";

export const createPlateTextLayer = ({
  ctx,
  id,
  text = "getText",
  title = null,
  size = 16,
  fontWeight = 400,
  offset = [0, 0],
}) => {
  return new TextLayer({
    id: id,
    data: ctx.props.data,
    getPosition: ctx.props.getPosition,

    getSize: (d) => {
      return ctx.props.getShowNow(d) ? size : 0;
    },

    getText: (d) => {
      return title ? title : ctx.props[text](d);
    },

    getColor: ctx.props.getColor,
    opacity: ctx.props.opacity,
    sizeScale: 1,
    fontFamily: ctx.props.fontFamily,
    fontWeight: fontWeight,
    getTextAnchor: ctx.props.getTextAnchor,
    getAlignmentBaseline: ctx.props.getAlignmentBaseline,
    getPixelOffset: offset,
    pickable: true,
    background: true,
    backgroundPadding: [0, 10],
    getBackgroundColor: [255, 255, 255, 0],

    fontSettings: {
      sdf: true,
      radius: 20,
    },
    updateTriggers: {
      all: ctx.props.updateTriggers.all,
      getText: ctx.props.updateTriggers.getText,
      getPosition: ctx.props.updateTriggers.getPosition,
    },
  });
};
