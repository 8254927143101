import { COORDINATE_SYSTEM } from "@deck.gl/core";
import { SimpleMeshLayer } from "@deck.gl/mesh-layers";
import { SphereGeometry } from "@luma.gl/engine";

import { EARTH_RADIUS_METERS } from "@/helpers/globe";

export const createGlobeMesh = () =>
  new SimpleMeshLayer({
    id: "earth-sphere",
    data: [0],
    mesh: new SphereGeometry({
      radius: EARTH_RADIUS_METERS,
      nlat: 80,
      nlong: 80,
    }),
    coordinateSystem: COORDINATE_SYSTEM.CARTESIAN,
    getPosition: [0, 0, 0],
    getColor: [255, 255, 255],
    material: {},
    getPolygonOffset: () => [0, 0],
  });
