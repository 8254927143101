import { H3HexagonLayer } from "@deck.gl/geo-layers";
import { getCityH3Data } from "@/deck/carto";

export const createCannesH3Layer = async () =>
  new H3HexagonLayer({
    id: "hexLayerCannes",
    data: await getCityH3Data("cannes"),
    autoHighlight: true,
    wireframe: false,
    filled: true,
    stroked: false,
    extruded: true,
    elevationScale: 3,
    lineWidthScale: 1000,
    getLineWidth: 10,
    getHexagon: (d) => d.h3index,
    getFillColor: (d) => [(1 - d.index / 255) * 255, 0, 0],
    getLineColor: () => [255, 0, 0],
    getPolygonOffset: () => [0, -100000],
    getElevation: (d) => d.index,
  });
