import { getRes0Indexes, h3ToChildren } from "h3-js";

const h3Indexes = [getRes0Indexes()];

export const getH3Indexes = (res) => {
  if (!h3Indexes[res]) {
    h3Indexes[res] = [];

    h3Indexes[0].forEach((h3Index) => {
      h3Indexes[res].push(...h3ToChildren(h3Index, res));
    });
  }

  return h3Indexes[res];
};
