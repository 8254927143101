export default {
  atlanta: [
    { fileName: "atlanta-music.svg", alt: "atlanta-music", component: "AtlantaMusic" },
    { fileName: "atlanta-podcast.svg", alt: "atlanta-podcast", component: "AtlantaPodcast" },
  ],
  singapore: [
    { fileName: "singapore-music.svg", alt: "singapore-music", component: "SingaporeMusic" },
    { fileName: "singapore-podcast.svg", alt: "singapore-podcast", component: "SingaporePodcast" },
  ],
  default: [
    { fileName: "atlanta-music.svg", alt: "atlanta-music", component: "AtlantaMusic" },
    { fileName: "atlanta-podcast.svg", alt: "atlanta-podcast", component: "AtlantaPodcast" },
  ],
};
