<template>
  <BaseFlatMap
    :view-state="viewState"
    :basemap="basemap"
    :set-view-state="setViewState"
    :reset-view-state="resetViewState"
    :set-map-loaded="onPageLoaded"
    :zoom-out-animation="false"
    :animation-status="false"
    :map-style="mapStyle"
  />
</template>

<script>
import { mapMutations, mapState, mapGetters } from "vuex";
import { LinearInterpolator, FlyToInterpolator } from "@deck.gl/core";

import { timeout } from "@/utils/misc";
import { setCartoDefaultCredentials, defaultCityPoint } from "@/deck/carto";
import layerManager from "@/deck/layerManager";
import { mapStyles } from "@/deck/maps";
import { getHexagonLayers } from "@/deck/h3";
import BaseFlatMap from "@/components/BaseFlatMap";

const POINT_ZOOM_DEFAULT = 15;

export default {
  name: "FlyToMap",
  components: { BaseFlatMap },
  props: {
    isAnimated: Boolean,
  },

  data: () => ({
    storesData: [],
    transitionInterpolator: new LinearInterpolator(),
    mapStyle: mapStyles.monoBlack3d,
    flyToPoints: [],
  }),

  async mounted() {
    await this.initialize();
    await timeout(2000);
    await this.changeLocation(1);
    this.flyToPoints = this.selectedOccasion?.occasion?.points || [defaultCityPoint];
    this.setViewState(this.getFirstPoint);
  },

  beforeDestroy() {
    this.setMapLoaded(false);
  },

  methods: {
    ...mapMutations("baseMap", ["setMapLoaded", "setViewState", "resetViewState", "setPageLoaded"]),

    changeLocation(index) {
      if (index > this.flyToPoints.length - 1) {
        return null;
      }

      const pointConfig = this.flyToPoints[index];
      this.setViewState({
        ...pointConfig.view,
        transitionDuration: pointConfig.flyTime,
        transitionInterpolator: new FlyToInterpolator(),
        onTransitionEnd: async () => {
          await timeout(pointConfig.stopTime);
          this.changeLocation(index + 1);
        },
      });
    },

    async onPageLoaded() {
      // Add 1 second just to be sure the map was loaded.
      await timeout(1000);
      this.setMapLoaded(true);
    },

    async initialize() {
      try {
        setCartoDefaultCredentials();

        const hexagonLayers = getHexagonLayers(undefined, { opacity: 0.01 });
        hexagonLayers.layers.forEach((layer) => layerManager.addLayer(layer));

        // TODO: We need a short time here to avoid recursion. Check this later one more time.
        await timeout(10);
      } catch (e) {
        console.log("ERROR (initialize):", e);
      }
    },
  },

  computed: {
    ...mapState("baseMap", ["viewState", "credentials", "basemap"]),
    ...mapGetters("occasions", ["selectedOccasion"]),

    getRouteName() {
      return this.$route.name;
    },

    getFirstPoint() {
      const { bearing, lat, long, pitch } = this.flyToPoints[this.flyToPoints.length - 1];

      return {
        longitude: long,
        latitude: lat,
        zoom: POINT_ZOOM_DEFAULT,
        pitch: pitch,
        bearing: bearing,
      };
    },
  },
};
</script>

<style scoped lang="scss"></style>
