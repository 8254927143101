<template>
  <v-app class="app">
    <div v-if="isShowMap" class="full-screen">
      <div v-if="isGlobeMap" class="globe full-screen">
        <MainGlobe class="globe__map full-screen" />
      </div>
      <div v-else class="globe full-screen">
        <FlyToMap class="globe__map full-screen" />
      </div>
    </div>
    <div class="content-wrapper not-clickable">
      <router-view></router-view>
    </div>
    <LoadingC9h v-if="isFirstLoader && isFirstLoading" />
    <Loading v-if="!isPageReady && !isFirstLoading" />
  </v-app>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";
import LoadingC9h from "@/components/LoadingC9h";
import MainGlobe from "@/layouts/MainGlobe";
import Loading from "@/components/Loading";
import FlyToMap from "@/layouts/FlyToMap";
import { FIRST_LOADER_MIN_TIME } from "@/settings";

export default {
  name: "Demo",
  components: { MainGlobe, LoadingC9h, FlyToMap, Loading },

  data() {
    return {
      isFirstLoader: true,
      isFirstLoaderMinTimePassed: false,
    };
  },

  mounted() {
    // TODO: Hide this for awhile. This event does not happen if the user have come through Login page.
    // window.addEventListener("load", this.onPageLoaded);
    this.onPageLoaded();

    setTimeout(() => {
      this.isFirstLoaderMinTimePassed = true;

      if (this.isPageReady) {
        this.hideFirstLoader();
      }
    }, FIRST_LOADER_MIN_TIME);
  },

  computed: {
    ...mapGetters("app", ["isGlobeMap", "isFirstLoading", "isMapLoading", "isShowMap"]),
    ...mapGetters(["mainGlobe/isPageReady", "baseMap/isPageReady"]),
    ...mapGetters("occasions", ["isOccasionsLoading"]),
    ...mapGetters("compose", ["isComposeLoading"]),
    ...mapGetters("selectExperience", ["isSelectExperienceLoading", "isLoadingVideo"]),

    isPageReady: {
      cache: false,
      get() {
        switch (this.$router.currentRoute.name) {
          case "Compose":
            return (
              !this.isComposeLoading &&
              !this.isMapLoading &&
              (this["mainGlobe/isPageReady"] || this["baseMap/isPageReady"])
            );

          case "SelectExperience":
            return !this.isMapLoading && (this["mainGlobe/isPageReady"] || this["baseMap/isPageReady"]);

          case "City":
            return !this.isMapLoading && (this["mainGlobe/isPageReady"] || this["baseMap/isPageReady"]);

          case "ExperienceSingleQr":
            return !this.isLoadingVideo;

          case "ExperienceMultiQr":
            return !this.isLoadingVideo;

          default:
            return !this.isOccasionsLoading && (this["mainGlobe/isPageReady"] || this["baseMap/isPageReady"]);
        }
      },
    },
  },
  methods: {
    ...mapMutations("app", ["changeIsFirstState"]),
    ...mapMutations(["baseMap/setPageLoaded", "mainGlobe/setPageLoaded"]),

    onPageLoaded() {
      this["baseMap/setPageLoaded"](true);
      this["mainGlobe/setPageLoaded"](true);
      // TODO: Hide this for awhile. This event does not happen if the user have come through Login page.
      // window.removeEventListener("load", this.onPageLoaded);
    },

    hideFirstLoader() {
      this.isFirstLoader = false;
      this.changeIsFirstState();
    },
  },

  watch: {
    isPageReady() {
      if (this.isFirstLoaderMinTimePassed) {
        this.hideFirstLoader();
      }
    },
  },
};
</script>

<style lang="scss">
@import "./src/scss/style";
.app {
  position: relative;
  width: 100%;
  height: 100%;
  min-height: 100%;
}
.globe {
  position: relative;

  &__map {
    position: absolute;
    top: 0;
    left: 0;
  }
}

.content-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

html {
  overflow: hidden !important;
}
</style>
