<template>
  <div class="sync-box">
    <div class="sync-box__box-title">{{ boxTitle }}</div>
    <div class="sync-box__box-occasion" v-for="(item, index) in occasionInfo" :key="item.title + index">
      <div>
        <span v-if="showItemCount(boxTitle)">{{ index + 1 }}.</span>
        {{ item.title }}
      </div>
      <div v-if="item.value" class="pl-3 text-right">{{ item.value }}</div>
      <div v-if="boxTitle === 'social'">{{ item.index }}</div>
      <div v-else-if="boxTitle === 'weather'">{{ item.index }}</div>
      <Donut
        v-if="item.index"
        :occasion-index="item.index"
        :donut-size="28"
        :donut-thickness="32"
        donut-background-color="#21212b"
      />
    </div>
  </div>
</template>

<script>
import Donut from "@/components/Donut";

export default {
  name: "SyncBox",
  components: { Donut },
  props: {
    occasionInfo: Array,
    boxTitle: String,
  },
  methods: {
    showItemCount(boxTitle) {
      return boxTitle === "search" || boxTitle === "events";
    },
  },
};
</script>

<style scoped lang="scss">
.sync-box {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  padding: 16px 16px;
  background: rgba(33, 33, 33, 0.5);
  backdrop-filter: blur(40px);
  border: 1px solid #5b5b5b;

  &__box-title {
    margin-bottom: 8px;
    font-weight: 400;
    font-size: 24px;
    line-height: 28px;
    letter-spacing: 0.08em;
    text-transform: uppercase;
  }

  &__box-occasion {
    display: flex;
    flex-grow: 1;
    justify-content: space-between;
    align-items: center;
    padding: 6px 0;
    border-top: 2px solid #454545;
  }

  &__box-occasion:last-of-type {
    padding-bottom: 0;
  }
}
</style>
