import { API_VERSIONS, BASEMAP } from "@deck.gl/carto";
import { debounce } from "@/utils/misc";
import layerManager from "@/deck/layerManager";

const token = process.env.VUE_APP_CARTO_ACCESS_TOKEN;

export const initialViewState = () => ({
  latitude: 43.5513,
  longitude: 7.0128,
  zoom: 4,
  pitch: 60,
  bearing: 0,
});

const setDelayedViewState = debounce((state, v) => {
  state.viewState = v;
});

export default {
  namespaced: true,
  state: {
    credentials: {
      apiBaseUrl: "https://gcp-us-east1.api.carto.com",
      apiVersion: API_VERSIONS.V3,
      accessToken: token,
    },
    basemap: BASEMAP.DARK_MATTER_NOLABELS,
    isMapLoaded: false,
    isPageLoaded: false,
    layersLoaded: false,
    viewState: initialViewState(),
    viewportFeatures: [],
  },

  mutations: {
    setMapLoaded(state, isMapLoaded) {
      state.isMapLoaded = isMapLoaded;
    },

    setPageLoaded(state, isPageLoaded) {
      state.isPageLoaded = isPageLoaded;
    },

    setViewState(state, viewState) {
      setDelayedViewState(state, viewState);
      layerManager.deckInstance?.setProps({ viewState: { ...viewState } });
    },

    resetViewState(state) {
      const viewState = initialViewState();
      state.viewState = viewState;
      layerManager.deckInstance?.setProps({ viewState: { ...viewState } });
    },
  },

  getters: {
    isPageReady(state) {
      return state.isPageLoaded && state.isMapLoaded;
    },
  },
};
