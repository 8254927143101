export const shuffle = (arr) => {
  for (let i = arr.length - 1; i > 0; i--) {
    const randomPosition = Math.floor(Math.random() * i);
    [arr[i], arr[randomPosition]] = [arr[randomPosition], arr[i]];
  }

  return arr;
};

export const getRandomInt = (min, max) => {
  return Math.floor(Math.random() * (max - min + 1)) + min;
};

export const getRandomElement = (arr) => arr[getRandomInt(0, arr.length - 1)];

export const getRandomCoordinate = (settings) => {
  return Array(settings.count)
    .fill({})
    .map((el, i) => ({
      long: getRandomInt(settings.longFrom, settings.longTo),
      lat: getRandomInt(settings.latFrom, settings.latTo),
      id: i,
    }));
};

export const getRandomListWithOutElements = (arr, elements) => {
  const filteredArr = arr.filter((el) => {
    return !elements.includes(el);
  });

  return shuffle(filteredArr);
};

export const getRandomListFromList = (data, num) => {
  const shuffled = shuffle([...data]);
  return shuffled.splice(0, num > data.length ? data.length : num);
};
