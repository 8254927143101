const spriteSettings = {
  small: { width: 28, height: 28, mask: false, anchorX: 0 },
  flag: { width: 171, height: 207, mask: false, anchorX: 0 },
};

export const occasionIconMapping = {
  workout: { ...spriteSettings.small, x: 0, y: 0 },
  breakfast: { ...spriteSettings.small, x: 0, y: 0 },
  "on the move": { ...spriteSettings.small, x: 40, y: 87 },
  "eating out": { ...spriteSettings.small, x: 0, y: 87 },
  studying: { ...spriteSettings.small, x: 40, y: 145 },
  working: { ...spriteSettings.small, x: 40, y: 203 },
  "pause at work": { ...spriteSettings.small, x: 0, y: 116 },
  "social media": { ...spriteSettings.small, x: 0, y: 145 },
  "going out": { ...spriteSettings.small, x: 0, y: 203 },
  "watching a movie": { ...spriteSettings.small, x: 0, y: 232 },
  "playing video games online": { ...spriteSettings.small, x: 0, y: 348 },
  diner: { ...spriteSettings.small, x: 0, y: 232 },
  socialising: { ...spriteSettings.small, x: 0, y: 116 },
  "binge watching series": { ...spriteSettings.small, x: 0, y: 0 },
  gaming: { ...spriteSettings.small, x: 0, y: 116 },
  "snack time": { ...spriteSettings.small, x: 0, y: 348 },
  "pause at": { ...spriteSettings.small, x: 40, y: 0 },
  "watching an e-sport game": { ...spriteSettings.small, x: 0, y: 406 },
  "me time": { ...spriteSettings.small, x: 40, y: 29 },
  "commute to university": { ...spriteSettings.small, x: 40, y: 0 },
  "commute to home": { ...spriteSettings.small, x: 0, y: 145 },
  "live event": { ...spriteSettings.small, x: 0, y: 348 },
  lunch: { ...spriteSettings.small, x: 0, y: 232 },
  "snack at university": { ...spriteSettings.small, x: 0, y: 145 },
  sleep: { ...spriteSettings.small, x: 0, y: 29 },
};

export const occasionMarkIconMapping = {
  workout: { ...spriteSettings.flag, x: 0, y: 0 },
  breakfast: { ...spriteSettings.flag, x: 0, y: 0 },
  "on the move": { ...spriteSettings.flag, x: 540, y: 0 },
  "eating out": { ...spriteSettings.flag, x: 0, y: 624 },
  studying: { ...spriteSettings.flag, x: 540, y: 416 },
  working: { ...spriteSettings.flag, x: 540, y: 832 },
  "pause at work": { ...spriteSettings.flag, x: 0, y: 832 },
  "social media": { ...spriteSettings.flag, x: 0, y: 1040 },
  "going out": { ...spriteSettings.flag, x: 180, y: 208 },
  "watching a movie": { ...spriteSettings.flag, x: 180, y: 416 },
  "playing video games online": { ...spriteSettings.flag, x: 360, y: 0 },
  diner: { ...spriteSettings.flag, x: 180, y: 416 },
  socialising: { ...spriteSettings.flag, x: 0, y: 832 },
  "binge watching series": { ...spriteSettings.flag, x: 0, y: 0 },
  gaming: { ...spriteSettings.flag, x: 0, y: 832 },
  "snack time": { ...spriteSettings.flag, x: 360, y: 0 },
  "pause at": { ...spriteSettings.flag, x: 360, y: 624 },
  "watching an e-sport game": { ...spriteSettings.flag, x: 360, y: 416 },
  "me time": { ...spriteSettings.flag, x: 360, y: 832 },
  "commute to university": { ...spriteSettings.flag, x: 360, y: 624 },
  "commute to home": { ...spriteSettings.flag, x: 0, y: 1040 },
  "live event": { ...spriteSettings.flag, x: 360, y: 0 },
  lunch: { ...spriteSettings.flag, x: 180, y: 416 },
  "snack at university": { ...spriteSettings.flag, x: 0, y: 1040 },
  university: { ...spriteSettings.small, x: 40, y: 261 },
  sleep: { ...spriteSettings.small, x: 0, y: 208 },
};

export const plateCityBgMapping = {
  background: { x: 0, y: 0, width: 300, height: 120, mask: false, anchorX: 20, anchorY: 28 },
};

// Spotify Demo mapping.
export const sliderOccasionIconMapping = {
  workout: "workout",
  breakfast: "workout",
  "on the move": "me-time",
  "eating out": "eating-out",
  studying: "commute-to-home",
  working: "lunch",
  "pause at work": "studying",
  "social media": "working",
  "going out": "social-media",
  "watching a movie": "going-out",
  "playing video games online": "socialising",
  diner: "going-out",
  socialising: "studying",
  "binge watching series": "workout",
  gaming: "studying",
  "snack time": "socialising",
  "pause at": "snack-time",
  "watching an e-sport game": "gaming",
  "me time": "pause-at",
  "commute to university": "snack-time",
  "commute to home": "working",
  "live event": "socialising",
  lunch: "going-out",
  "snack at university": "working",
  sleep: "breakfast",
};

// Cannes Demo mapping.
// export const occasionIconMapping = {
//   workout: { ...spriteSettings.small, x: 0, y: 0 },
//   breakfast: { ...spriteSettings.small, x: 0, y: 29 },
//   "on the move": { ...spriteSettings.small, x: 0, y: 58 },
//   "eating out": { ...spriteSettings.small, x: 0, y: 87 },
//   studying: { ...spriteSettings.small, x: 0, y: 116 },
//   working: { ...spriteSettings.small, x: 0, y: 145 },
//   "pause at work": { ...spriteSettings.small, x: 0, y: 174 },
//   "social media": { ...spriteSettings.small, x: 0, y: 203 },
//   "going out": { ...spriteSettings.small, x: 0, y: 232 },
//   "watching a movie": { ...spriteSettings.small, x: 0, y: 261 },
//   "playing video games online": { ...spriteSettings.small, x: 0, y: 290 },
//   diner: { ...spriteSettings.small, x: 0, y: 319 },
//   socialising: { ...spriteSettings.small, x: 0, y: 348 },
//   "binge watching series": { ...spriteSettings.small, x: 0, y: 377 },
//   gaming: { ...spriteSettings.small, x: 0, y: 406 },
//   "snack time": { ...spriteSettings.small, x: 40, y: 0 },
//   "pause at": { ...spriteSettings.small, x: 40, y: 29 },
//   "watching an e-sport game": { ...spriteSettings.small, x: 40, y: 58 },
//   "me time": { ...spriteSettings.small, x: 40, y: 87 },
//   "commute to university": { ...spriteSettings.small, x: 40, y: 116 },
//   "commute to home": { ...spriteSettings.small, x: 40, y: 145 },
//   "live event": { ...spriteSettings.small, x: 40, y: 174 },
//   lunch: { ...spriteSettings.small, x: 40, y: 203 },
//   "snack at university": { ...spriteSettings.small, x: 40, y: 232 },
//   sleep: { ...spriteSettings.small, x: 40, y: 261 },
// };

// export const occasionMarkIconMapping = {
//   workout: { ...spriteSettings.flag, x: 0, y: 0 },
//   breakfast: { ...spriteSettings.flag, x: 0, y: 208 },
//   "on the move": { ...spriteSettings.flag, x: 0, y: 416 },
//   "eating out": { ...spriteSettings.flag, x: 0, y: 624 },
//   studying: { ...spriteSettings.flag, x: 0, y: 832 },
//   working: { ...spriteSettings.flag, x: 0, y: 1040 },
//   "pause at work": { ...spriteSettings.flag, x: 180, y: 0 },
//   "social media": { ...spriteSettings.flag, x: 180, y: 208 },
//   "going out": { ...spriteSettings.flag, x: 180, y: 416 },
//   "watching a movie": { ...spriteSettings.flag, x: 180, y: 624 },
//   "playing video games online": { ...spriteSettings.flag, x: 180, y: 832 },
//   diner: { ...spriteSettings.flag, x: 180, y: 1040 },
//   socialising: { ...spriteSettings.flag, x: 360, y: 0 },
//   "binge watching series": { ...spriteSettings.flag, x: 360, y: 208 },
//   gaming: { ...spriteSettings.flag, x: 360, y: 416 },
//   "snack time": { ...spriteSettings.flag, x: 360, y: 624 },
//   "pause at": { ...spriteSettings.flag, x: 360, y: 832 },
//   "watching an e-sport game": { ...spriteSettings.flag, x: 360, y: 1040 },
//   "me time": { ...spriteSettings.flag, x: 540, y: 0 },
//   "commute to university": { ...spriteSettings.flag, x: 540, y: 208 },
//   "commute to home": { ...spriteSettings.flag, x: 540, y: 416 },
//   "live event": { ...spriteSettings.flag, x: 540, y: 624 },
//   lunch: { ...spriteSettings.flag, x: 540, y: 832 },
//   "snack at university": { ...spriteSettings.flag, x: 540, y: 1040 },
//   university: { ...spriteSettings.small, x: 40, y: 261 },
//   sleep: { ...spriteSettings.small, x: 720, y: 0 },
// };

// export const sliderOccasionIconMapping = {
//   workout: "workout",
//   breakfast: "breakfast",
//   "on the move": "on-the-move",
//   "eating out": "eating-out",
//   studying: "studying",
//   working: "working",
//   "pause at work": "pause-at-work",
//   "social media": "social-media",
//   "going out": "going-out",
//   "watching a movie": "watching-movie",
//   "playing video games online": "playing-video-games-online",
//   diner: "diner",
//   socialising: "socialising",
//   "binge watching series": "watching-series",
//   gaming: "gaming",
//   "snack time": "snack-time",
//   "pause at": "pause-at",
//   "watching an e-sport game": "watching-an-e-sport-game",
//   "me time": "me-time",
//   "commute to university": "commute-to-university",
//   "commute to home": "commute-to-home",
//   "live event": "live-event",
//   lunch: "lunch",
//   "snack at university": "snack-at-university",
//   sleep: "sleep",
// };
