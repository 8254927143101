<template>
  <div class="clickable info-container sync-diagram">
    <div class="info-header clickable">
      <div class="info-header__left">
        <v-btn class="go-back-btn clickable" @click="onGoBack" fixed icon large color="#fff">
          <v-icon size="36">mdi-chevron-left</v-icon>
        </v-btn>
      </div>
      <div class="info-header_center" style="justify-self: center">
        <img class="logo" :src="require('@/assets/logo/logo-sync-exact.svg')" style="height: 80px" alt="Sync" />
      </div>
      <div class="info-header__right">{{ curDate }}</div>
    </div>

    <div class="info-footer clickable">
      <div class="info-footer__left">
        <router-link :to="{ name: 'Occasions' }">
          <img class="logo" :src="require('@/assets/images/choreograph.svg')" alt="Choreograph" />
        </router-link>
      </div>
      <div class="info-footer__center"></div>
      <div class="info-footer__right">
        <router-link class="go-next-btn" :to="{ name: 'Compose' }">
          <span>next</span>
          <img :src="require('@/assets/icons/arrow-right.svg')" alt="Next" />
        </router-link>
      </div>
    </div>

    <div class="info-content">
      <div class="sync-diagram__diagram">
        <div class="sync-diagram__row">
          <div class="row-content sync-diagram__top">
            <SyncBox class="sync-diagram__sync-box" :occasion-info="firstRowData" box-title="occasion" />
          </div>
          <div class="arrows">
            <img :src="require('@/assets/icons/sync/arrows-1-2.svg')" alt="" />
          </div>
        </div>
        <div class="sync-diagram__row">
          <div class="row-content sync-diagram__middle">
            <SyncBox
              class="sync-diagram__sync-box"
              v-for="category in secondRowData"
              :key="category.title"
              :occasion-info="category.data"
              :box-title="category.title"
            />
          </div>
          <div class="arrows">
            <img :src="require('@/assets/icons/sync/arrows-2-3.svg')" alt="" />
          </div>
        </div>
        <div class="sync-diagram__row">
          <div class="row-content sync-diagram__bottom">
            <SyncBox
              class="sync-diagram__sync-box"
              v-for="category in thirdRowData"
              :key="category.title"
              :occasion-info="category.data"
              :box-title="category.title"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from "vuex";
import { getDateString } from "@/utils/dateTime";
import SyncBox from "@/components/SyncBox";

export default {
  name: "SyncDiagram",
  components: { SyncBox },
  data() {
    return {
      occasionsInfo: [],
      occasionsCategoriesInfo: {
        context: {
          key: "context",
          title: "context",
          data: [],
        },
        needState: {
          key: "need_state",
          title: "need",
          data: [],
        },
        choiceDriver: {
          key: "choice_driver",
          title: "passion",
          data: [],
        },
      },
      occasionsCityInfo: {
        social: {
          title: "social",
          data: [],
        },
        search: {
          title: "search",
          data: [],
        },
        events: {
          title: "events",
          data: [],
        },
        weather: {
          title: "weather",
          data: [],
        },
      },
    };
  },
  created() {
    if (
      !this.selectedOccasion?.occasion?.cityId ||
      !this.selectedOccasion?.occasion?.points.length ||
      !this.selectedOccasion?.occasion?.occasions.length
    ) {
      this.$router.replace("/");
    }
  },

  async beforeMount() {
    await this.getCityDetails({ cityId: this.cityId, occasionName: this.occasionName });
  },

  computed: {
    ...mapGetters("occasions", [
      "selectedOccasion",
      "occasionTitles",
      "cityDetails",
      "activeSlideOccasion",
      "activeSlide",
      "occasionCategories",
    ]),

    occasionName: {
      cache: false,
      get() {
        return this.activeSlideOccasion.occasion_name;
      },
    },

    curDate() {
      return getDateString();
    },

    cityId() {
      return this.selectedOccasion.occasion.cityId;
    },

    occasions() {
      let occasions = this.selectedOccasion?.occasion?.occasions;

      if (!occasions) {
        return;
      }

      return [occasions[0], ...occasions.slice(1, 7).reverse()];
    },

    firstRowData() {
      return [
        {
          title: this.activeSlideOccasion?.occasion_title?.toLowerCase(),
          index: this.activeSlideOccasion?.occasion_index,
        },
        this.getNextOccasion(1),
        this.getNextOccasion(2),
      ];
    },

    secondRowData() {
      return [
        {
          title: "context",
          data: this.fillCategory("context", this.activeSlideOccasion?.context),
        },
        {
          title: "need",
          data: this.fillCategory("needState", this.activeSlideOccasion?.need_state),
        },
        {
          title: "passion",
          data: this.fillCategory("choiceDriver", this.activeSlideOccasion?.choice_driver),
        },
      ];
    },

    thirdRowData() {
      return [
        {
          title: "Social & Search",
          data: this.getCityDetail("social_search"),
        },
        {
          title: "Environment",
          data: this.getCityDetail("environment"),
        },
        {
          title: "Who With",
          data: this.getCityDetail("who_with"),
        },
        {
          title: "Time & Location",
          data: this.getCityDetail("time_location"),
        },
      ];
    },
  },

  methods: {
    ...mapMutations("occasions", ["setOccasionsInfo", "setOccasionsCategoriesInfo", "setOccasionsCityInfo"]),
    ...mapActions("occasions", ["getCityDetails"]),

    onGoBack() {
      this.$router.go(-1);
    },

    fillCategory(categoryName) {
      const categoryKey = this.occasionsCategoriesInfo[categoryName].key;
      const secondOccasion = this.getNextOccasion(1).occasion;
      const thirdOccasion = this.getNextOccasion(2).occasion;

      return [
        {
          title: this.activeSlideOccasion?.[categoryKey]?.toLowerCase(),
          index: this.activeSlideOccasion?.[`${categoryKey}_index`],
        },
        {
          title: secondOccasion?.[categoryKey]?.toLowerCase(),
          index: secondOccasion?.[`${categoryKey}_index`],
        },
        {
          title: thirdOccasion?.[categoryKey]?.toLowerCase(),
          index: thirdOccasion?.[`${categoryKey}_index`],
        },
      ];
    },

    getCityDetail(detailName) {
      const titles = {
        environment: {
          weather_temp: "Weather Temperature",
          weather_sky: "Weather Sky",
        },
        time_location: {
          time: "Time",
          meal_time: "Meal Time",
          location: "Location",
        },
      };

      let data;

      switch (detailName) {
        case "environment":
        case "time_location":
          data = this.cityDetails?.[detailName] || {};
          return Object.keys(data).map((key) => ({
            title: titles[detailName]?.[key] || key,
            value: data[key]?.replace("_", " "),
          }));
        case "social_search":
        case "who_with":
        default:
          data = this.cityDetails?.[detailName] || [];
          return data.map((item) => ({ title: item }));
      }
    },

    getNextOccasion(occasionIndex) {
      const listLength = this.occasions?.length || 0;
      const shiftedIndex = this.activeSlide - occasionIndex;
      const index = shiftedIndex < 0 ? listLength + shiftedIndex : shiftedIndex;

      return {
        title: this.occasions?.[index]?.occasion_title?.toLowerCase(),
        index: this.occasions?.[index]?.occasion_index,
        occasionIndex: index,
        occasion: this.occasions?.[index],
      };
    },
  },
};
</script>

<style scoped lang="scss">
.sync-diagram-logo {
  height: 80px;
}

.sync-diagram-date {
  font-weight: 400;
  font-size: 24px;
  line-height: 28px;
  text-align: right;
  letter-spacing: 0.08em;
  text-transform: uppercase;
}

.sync-diagram {
  .info-content {
    padding-left: 28px;
    padding-right: 28px;
  }

  &__diagram {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1.2fr 1.2fr 1fr;
    max-height: 100%;

    .arrows {
      display: flex;
      justify-content: center;

      img {
        display: block;
      }
    }
  }

  &__row {
    display: flex;
    flex-direction: column;

    .row-content {
      flex-grow: 1;
    }
  }

  &__top {
    margin-top: 10px;
    // margin-bottom: 28px;
    display: grid;
    grid-template-columns: 3fr 2fr 3fr;
    grid-template-rows: 1fr;
    grid-column-gap: 36px;
    grid-row-gap: 0;

    .sync-diagram__sync-box {
      grid-column-start: 2;
    }
  }

  &__middle {
    // margin-bottom: 28px;
    display: grid;
    grid-template-columns: 1fr repeat(3, 2fr) 1fr;
    grid-template-rows: 1fr;
    grid-column-gap: 24px;
    grid-row-gap: 0;

    .sync-diagram__sync-box:first-of-type {
      grid-column-start: 2;
    }
  }

  &__bottom {
    display: grid;
    grid-template-columns: repeat(4, 2fr);
    grid-template-rows: 1fr;
    grid-column-gap: 24px;
    grid-row-gap: 0;

    ::v-deep {
      .sync-box__box-occasion {
        flex-grow: 0;
        align-items: flex-start;
      }
    }
  }

  .go-next-btn {
    position: absolute;
    bottom: 30px;
    right: 30px;
    text-decoration: none;
    font-weight: 500;
    font-size: 20px;
    line-height: 28px;
    letter-spacing: 0.04em;
    text-transform: uppercase;

    img {
      padding-left: 20px;
    }
  }
}
</style>
