export default {
  namespaced: true,

  state: {
    isSelectExperienceLoading: true,
    isExperienceChecked: false,
    selectedExperience: "",
    isLoadingVideo: true,
  },

  mutations: {
    setSelectExperienceLoading(state, selectExperienceLoading) {
      state.isSelectExperienceLoading = selectExperienceLoading;
    },

    setExperienceChecked(state, val) {
      state.isExperienceChecked = val;
    },

    setSelectedExperience(state, val) {
      state.selectedExperience = val.toUpperCase();
    },

    setIsLoadingVideo(state, val) {
      state.isLoadingVideo = val;
    },
  },

  getters: {
    isSelectExperienceLoading(state) {
      return state.isSelectExperienceLoading;
    },

    isExperienceChecked(state) {
      return state.isExperienceChecked;
    },

    selectedExperience(state) {
      return state.selectedExperience;
    },

    isLoadingVideo(state) {
      return state.isLoadingVideo;
    },
  },
};
