const STOP_IN_DEFAULT = 1000;
const NEXT_IN_DEFAULT = 2000;
const ACTIVE_CLASS_DEFAULT = "run-animation";

/**
 * Animation class by script
 */
export class Animation {
  /**
   * @param {object} props.context                - The context Vue component.
   * @param {object} props.context.$refs          - Link to the html element in the component.
   * @param {number} props.initStep               - Start slide number.
   * @param {boolean} props.infinite              - Flag indicating the infinity of animation.
   * @param {object[]} props.config               - Animation config.
   * @param {number} props.config[].nextStepIn    - Time to move to the next animation.
   * @param {number} props.config[].stopIn        - Time of existence of css class.
   * @param {string} props.config[].className     - Css class name.
   * @param {string} props.config[].refs[]        - List of names refs.
   */
  constructor(props) {
    const { context, config, initStep, infinite } = props;
    this.config = config;
    this.initStep = initStep || 0;
    this.isInfinite = infinite || false;
    this.currentStep = initStep;
    this.context = context;
    this.isPlayAnimation = true;
    this.animationElements = {};
  }

  startAnimation(step = this.initStep) {
    this.currentStep = step;
    this.isPlayAnimation = true;
    this.nextStep();
  }

  stopAnimation() {
    this.isPlayAnimation = false;
  }

  nextStep() {
    if (!this.isPlayAnimation) {
      return;
    }

    const { refs, nextStepIn = NEXT_IN_DEFAULT, className = ACTIVE_CLASS_DEFAULT } = this.config[this.currentStep];

    refs.forEach((el) => {
      if (this.animationElements[el]) {
        this.context.$refs[el]?.classList.remove(className);

        // That's a hack for redrawing the browser.
        void this.context.$refs[el]?.offsetWidth;

        this.animationElements[el] = { className, id: this.currentStep };
        this.context.$refs[el]?.classList.add(className);
      } else {
        // That's a hack for redrawing the browser.
        void this.context.$refs[el]?.offsetWidth;

        this.animationElements[el] = { className, id: this.currentStep };
        this.context.$refs[el]?.classList.add(className);
      }
    });

    setTimeout(() => {
      if (this.currentStep < this.config.length - 1) {
        ++this.currentStep;
        this.nextStep();
        return;
      }

      if (this.isInfinite) {
        this.currentStep = 0;
        this.nextStep();
      }
    }, nextStepIn);

    this._removeAnimation(this.currentStep);
  }

  _removeAnimation(step) {
    const { refs, stopIn = STOP_IN_DEFAULT, className = ACTIVE_CLASS_DEFAULT } = this.config[this.currentStep];
    setTimeout(() => {
      refs.forEach((el) => {
        if (this.animationElements[el] && this.animationElements[el].id === step) {
          delete this.animationElements[el];
          this.context.$refs[el]?.classList.remove(className);
        }
      });
    }, stopIn);
  }
}
