import { globeInitialViewState } from "@/helpers/globe";

export default {
  namespaced: true,
  state: {
    iaMapLoaded: false,
    isPageLoaded: false,
    isUserStopAnimation: false,
    isShowPlateLayer: true,
    visibleCities: [],
    opacityCitiesAll: 1,
    citiesOccasionsData: [],
    viewState: globeInitialViewState,
  },

  mutations: {
    setMapLoaded(state, val) {
      state.iaMapLoaded = val;
    },

    setPageLoaded(state, val) {
      state.isPageLoaded = val;
    },

    setIsUserStopAnimation(state, value) {
      state.isUserStopAnimation = value;
    },

    setIsShowPlateLayer(state, value) {
      state.isShowPlateLayer = value;
    },

    setOpacityCitiesAll(state, value) {
      state.opacityCitiesAll = value;
    },

    setVisibleCities(state, value) {
      state.visibleCities = value;
    },

    onPlayRotate(state) {
      state.isUserStopAnimation = false;
      state.opacityCitiesAll = 1;
    },

    showPlateCities(state) {
      state.isUserStopAnimation = true;
      state.isShowPlateLayer = false;
      state.opacityCitiesAll = 0;
    },

    showPlateOccasions(state) {
      state.isUserStopAnimation = false;
      state.isShowPlateLayer = true;
      state.opacityCitiesAll = 1;
    },

    setCitiesOccasionsData(state, data) {
      state.citiesOccasionsData = data;
    },

    setViewState(state, viewState) {
      state.viewState = viewState;
    },
  },

  getters: {
    isPageReady(state) {
      return state.iaMapLoaded && state.isPageLoaded;
    },

    citiesOccasionsData(state) {
      return state.citiesOccasionsData;
    },

    isUserStopAnimation(state) {
      return state.isUserStopAnimation;
    },

    isShowPlateLayer(state) {
      return state.isShowPlateLayer;
    },

    opacityCitiesAll(state) {
      return state.opacityCitiesAll;
    },

    visibleCities(state) {
      return state.visibleCities;
    },

    viewState(state) {
      return state.viewState;
    },
  },
};
