import { CompositeLayer } from "@deck.gl/core";
import { IconLayer } from "@deck.gl/layers";

import { getOccasionPlateZ } from "@/helpers/occasions";
import { createPlateTextLayer } from "@/components/layers/PlateTextLayer";
import { createPlateIconLayer } from "@/components/layers/PlateIconLayer";
import { plateCityBgMapping } from "@/assets/icons/occasions-sprite";

export const createExperiencePlateLayer = async (ctx) => {
  class ExperiencePlateLayer extends CompositeLayer {
    renderLayers() {
      return [
        new IconLayer({
          id: "experience-background",
          data: this.props.data,
          getPosition: this.props.getPosition,
          pickable: true,
          opacity: 1,
          iconAtlas: require("/src/assets/icons/plate-city-bg.svg"),
          iconMapping: plateCityBgMapping,
          getIcon: () => "background",
          billboard: true,
          sizeScale: 4,
          getSize: () => 36,
          getPixelOffset: [0, 0],
        }),
        ...createPlateIconLayer({ ctx: this, id: "experience-occasion", offset: [0, 28] }),
        createPlateTextLayer({ ctx: this, id: "experience-title", text: "getCityName", size: 18, offset: [0, 0] }),
      ];
    }
  }

  ExperiencePlateLayer.layerName = "ExperiencePlateLayer";

  ExperiencePlateLayer.defaultProps = {
    fontFamily: "Urbanist, sans-serif",
    getTextAnchor: "start",
    getAlignmentBaseline: "center",
    billboard: true,
    pickable: true,

    getPosition: {
      type: "accessor",
      value: (d) => [d.long, d.lat, getOccasionPlateZ()],
    },

    getCityName: {
      type: "accessor",
      value: (d) => d.cityId?.toUpperCase(),
    },

    getIcon: {
      type: "accessor",
      value: () => ctx.visibleCities,
    },

    getColor: {
      type: "accessor",
      value: () => [255, 255, 255],
    },

    getShowNow: {
      type: "accessor",
      value: () => true,
    },
  };

  return (layerCtx, _animationTick) => {
    return new ExperiencePlateLayer({
      id: "experience-plate-layer",
      data: layerCtx.visibleCities,

      updateTriggers: {
        getColor: [_animationTick],
        getPosition: [_animationTick],
        all: [_animationTick],
      },

      onClick: (point) => {
        layerCtx.setSelectedOccasion(point.object);
        layerCtx.$store.commit("app/setIsShowMap", false);
        const files = point.object?.occasions[0]?.qr?.length;
        if (files > 1) {
          layerCtx.$router.push({ path: "/experience-multi-qr" });
        } else {
          layerCtx.$router.push({ path: "/experience-single-qr" });
        }
      },
    });
  };
};
