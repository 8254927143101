<template>
  <div
    class="content loading-page"
    :style="{ 'background-image': `url(${require('@/assets/images/stars-foff.jpg')})` }"
  >
    <img class="loader" :src="require('@/assets/images/circle-loading.svg')" alt="Loading..." />
  </div>
</template>

<script>
export default {
  name: "Loading",

  data() {
    return {};
  },
};
</script>

<style lang="scss" scoped>
@import "../scss/style";

.loading-page {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10;
  background-size: 100% auto;
  background-repeat: no-repeat;
  background-position: center;
}
.loader {
  width: 400px;
}
</style>
